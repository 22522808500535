import { O } from "@angular/cdk/keycodes";
import { CANCELLED } from "dns";


export const en = {
    _NONE :'-',
    NO_BRANDS: "No brand",
    BRANDS: "brands",
    BRANDS_CLIENT: "brand(s) client",
    BRANDS_LIST: "Brand list",
    I_UNDERSTAND: "I understand",
    CANCELLED: "Cancelled",
    CURRENT_ORDER: "Current order : ",
    RELECTURE_EXTERN: "Proofreading",
    RELECTURE_INTERN: "Internal proofreading",
    LAST_REVISION: "Last revision",
    ARCHIVED: "Archived",
    FROM_ARCHIVE: "From archive",
    SHOW_ARCHIVED_VERSION: "Show archived versions",
    PR_UPDATED: "Proofreading updated.",
    RETURN: "Back",
    LANGUAGES: "Languages",
    CANCEL_FULLSCREEN: "Minimize",
    INFORMATION: "Information",
    ASSERT_INFORMATIONS: "Is the information below still accurate?",
    COMMENTARY: "Comments",
    COMMENTARY_PLACEHOLDER: "Please specify the changes to be made here",
    RELECTURE_MEMBERS: "Proofreading members",
    TODO: "To be verified",
    DONE: "Done",
    DOWNLOAD: "Download",
    DECISION_TAKEN_BY: "by :",
    PDF_CAN_REUPLOAD: "You can reupload a new PDF file",
    UPLOAD: "Upload",
    PDF_PLEASE_REUPLOAD: "Please upload a new PDF file",
    OLD_PROJECT: "Limited project",
    OLD_PROJECT_NO_PR : "old project",
    REORDER: "Reorder",
    ALREADY_EXISTS: "Already exists",
    RESERVED_KEYWORD: "Reserved keyword",
    NOT_ALLOWED_ON_ARCHIVED: "This action is not allowed on an archived project",
    SELECT_A_CUSTOMER_OR_CHOOSE_ALL_CUSTOMER: "Select a customer or choose all customers",
    SAVE_SUCCESS: "Saved successfully",
    PATCH_NOTES: {
        LIVECO_UPDATE: "LiveCo update",
        TITLE: "Title",
        COMMENT: "Comment",
        DATE: "Date",
        ARE_YOU_SURE_DELETE_PATCH_NOTES: "Are you sure you want to delete this patch note?",
        DELETE_SUCCESS: "Patch note deleted",
        NEW: "New patch note",
        NOTES : "Patch notes",


    },
    BRAND: {
        MANAGE: "Manage brands",
        BACK_TO_TABLE: "Back to the brand list",
        LABEL: "Label",
        BRAND_USERS: "Brand users",
        ENABLE_EASDIADEM: "Enable Easy Diadem",
        MANAGE_SFTP : "Manage SFTP",
        ENABLE_CATEGORIES: "Enable categories",
        ENABLE_SFTP: "Enable SFTP",
        MANAGE_CATEGORIES_FLAGS: "Manage categories",
        MANAGE_FLAGS: "Manage flags",
        SAVE_SFTP_CONFIG: "Save SFTP configuration",
        EMPLTY_MEANS_ALL: "Empty means all",
        KNOW_WHAT_YOU_DO_WARNING: "Be careful with this option, use it only if you know what you are doing",

        FLAGS: {
            SELECT_REQUEST_TYPES: "Select request types",
            SAVE_EZD: "Save Easydiadem rights",
            SAVE: 'Save flags',
            SAVE_CATEGORIES:"Save categories",
            PLACEHOLDER: "Write a flag (case sensitive)"
        },
        CATEGORIES: {
            ADD: "Add a category",
            NEW: "New category",
            DELETE: "Delete",
            SAVE: "Save",
            PLACEHOLDER: "Category name",
            ADD_SUB_CATEGORY: "Add a sub-category",
            ARE_YOU_SURE_DELETE_CATEGORY_TEXT: "Are you sure you want to delete this category?",
            ALREADY_EXISTS: "This category already exists",


        }
    },
    FORM: {
        "TEXT-CORRECTION": "Text correction",
        "PDF-REPOSITORY": "PDF",
        "FILE-REDELIVERY": "File redelivery",
        HEADS: {
            PLAN: "Plan",
            CUSTOMER_BRIEF: "Customer Brief",
            COLOUR: "Color",
            CREA_FILES: "Creative files",
            IMAGES_TO_RETOUCH: "Images to be retouched",
            TECHNICAL_PLAN: "Technical plan",
            TEXT_SHEET: "Text file",
            PRINTING_METHOD: "Printing method",
            COLORING: "Coloring",
            BRAND_CHART: "Brand guideline",
            DEADLINE: "Deadline",
            VALIDATION_CYCLE: "Validation cycle",
            CLIENT_CONTACT: "Customer contact",
            ARCHIVE_EXISTING_REFERENCE: "Existing reference archive",
            SOURCE_PROJECT: "Source project",
            PRINTER: "Printer",
            SEQ_COLORS: "Printing sequence",
            TEXT: "Text file",
            RELECTURE: "Proofreading members",
            FINALIZATION: "Finalization",
            EPSON_CONTACT: "Contact",

            MEMBERS: 'Members',

            GENERAL_INFO: "Master project informations",
            L2PUB: 'Project identifier',
            GENERAL: 'General informations',
            NAME: "Informations",
            COLORS: "Colors",

            QUANTITY: "Quantity",
            NUM_JOB: "Job number",
            FILES: "File(s)",
            FILE: "File",
            DESCRIPTION: "Description",
            SHOOTING: "Photo shoots",
            REDELIVERYTYPE: "Redelivery type",
            BASE: "Base informations",
            PRINTING: "Printing informations",
            CODES: "Codes (QRCode, Gencod...)",
            CARTOUCHE: "Cartouche",
            CREA: "CREA",
            EXE: "EXE",
            REPRO: "REPRO",
            HD: "HD",
            PDF_REPO: "PDF repository",

            PROOFREADING_ID: "Select a flow", // Displayed as a flow selector on the client side
            OTHER: "Other",
        },
        ERRORS: {
            GENERAL_INFO: {
                CPP: "Please choose a CPP",
                CUSTOMER: "Please choose a customer",
                MARQUE: "Please choose a brand",
                DESIGNATION: "Please choose a designation",
                CONTACT: "Please choose a contact",
                FAMILY: "Please choose a family",
                L2PUB: "Please choose a project identifier",
            },
            L2PUB: "Please choose a project identifier",
            GENERAL: {
                SERVICE_EXE: "Please choose a flow",
                SERVICE_HD: "",
                SERVICE_REPRO: "",
                SERVICE_OTHER: "",
                SERVICE_REPO: "",
                DESIGNATION: "Please choose a designation",
                CATEGORY: "Please choose a category",
                SUB_CATEGORY: "Please choose a sub-category",
                ID_JOBTEAM: "Invalid job number",

            },
            PRINTING: {
                PRINTER: "Please choose a printer",
                PROCESS: "Please choose a process",
                SUPPORT: "Please choose a support",
                PLAN_TECHNIQUE: "Please choose a technical plan",

            },
            MEMBERS: "Please choose a member",
            NAME: "Please name your project",
            FILES: "Please choose a file",
            RELECTURE: {
                MEMBERS: "Please choose a member",
            },
            SOURCE_PROJECT: {
                IDENTIFIER: "Please indicate the concerned project",
            },
            PLAN: {
                FILE_PLAN: "Please choose a file"
            },
            FINALIZATION: {
                RESPONSABILITY_AGREEMENT: "Please accept the conditions",
            },
            QUANTITY: "Please indicate a quantity",
            EPSON_CONTACT: {
                FIRSTNAME: "Please indicate a first name",
                LASTNAME: "Please indicate a last name",
                TEL: "Please indicate a phone number",
                EMAIL: "Please indicate an email",

                ADDRESS: {
                    STREET: "Please indicate an address",
                    CP: "Please indicate a postal code",
                    CITY: "Please indicate a city"
                }
            },
            REDELIVERYTYPE: "Please indicate a redelivery type",
            DESCRIPTION: "Please indicate a description",
            SHOOTING: "Please indicate a shooting",
            SEQ_COLORS: {
                COMMENT: "Please indicate a comment \n for the printing sequence",
            },
            PRINTER: {
                COMMENT: "Please indicate a comment \n for the printer",
            },
            CODES: {
                GENCOD: {
                    CODE: "Please indicate a valid code",
                }
            },
            COLORS: {
                IMPRESSION_COULEUR1: "Please indicate a valid color 1",
                IMPRESSION_COULEUR2: "Please indicate a valid color 2",
                IMPRESSION_COULEUR3: "Please indicate a valid color 3",
                IMPRESSION_COULEUR4: "Please indicate a valid color 4",
                IMPRESSION_COULEUR5: "Please indicate a valid color 5",
                IMPRESSION_COULEUR6: "Please indicate a valid color 6",
                IMPRESSION_COULEUR7: "Please indicate a valid color 7",
                IMPRESSION_COULEUR8: "Please indicate a valid color 8",
                IMPRESSION_COULEUR9: "Please indicate a valid color 9",
                IMPRESSION_COULEUR10: "Please indicate a valid color 10",
                IMPRESSION_FINITION1: "Please indicate a valid finish 1",
                IMPRESSION_FINITION2: "Please indicate a valid finish 2",
                IMPRESSION_FINITION3: "Please indicate a valid finish 3",
                IMPRESSION_FINITION4: "Please indicate a valid finish 4",
                IMPRESSION_FINITION5: "Please indicate a valid finish 5",
                IMPRESSION_FINITION6: "Please indicate a valid finish 6",
                IMPRESSION_FINITION7: "Please indicate a valid finish 7",
                IMPRESSION_FINITION8: "Please indicate a valid finish 8",
                IMPRESSION_FINITION9: "Please indicate a valid finish 9",
                IMPRESSION_FINITION10: "Please indicate a valid finish 10"

            },

            CONTACT: "Please indicate an address",
            PDF_REPO: {
                FILE_REPO:'Please choose a file',
            }

        },
        ADD_FILE_UPLOADER: "Add a file",
        REMOVE_FILE_UPLOADER: "Remove the file",
        REUPLOAD_FILE: "Reupload the file (will increment the version)",
    },
    PDF_REPO: "PDF repository",

    PDF_REPO_FLOW: {
        LEGEND: "Each file will be linked to a review",
        LINK: "Go to relecture",
    },

    ASSESSMENT: {
        PENDING: "Pending",
        APPROVE: "Approved",
        REJECT: "Rejected",
        IN_PROGRESS: "Awaiting approval",
        READONLY: "Readonly",
        READONLY_PROOFSCOPE: "Review",
        PAUSED: "Paused cycle"
    },
    STATE: "Status",
    APPROVAL_SENT: "Received approvals",
    SEE_ALL: "See all",
    ADDRESS: {
        ADDRESS: "Address",
        LASTNAME: "Last name",
        FIRSTNAME: "First name",
        STREET: "Address (number and street name)",
        CITY: "City",
        CP: "Zip code",
        EMAIL: "Email address",
        TEL: "Telephone number"
    },
    FEATURE_TO_COME: "Feature(s) to come",
    RECENT_PROJECTS: "History",
    LAST_10_ENTRIES: "Last 10 history entries",
    HI_DESCRIPTION: "What elements would you like to highlight on your Hero Image? (it will be delivered in jpeg and png formats - Low res and High res) : ",
    CLOSED: "Closed",
    FULL_SCREEN: "Fullscreen",
    NO_FULL_SCREEN: "Normal screen",
    COMPARE_DOC_A: "Document A",
    COMPARE_DOC_B: "Document B",
    COMPARE_DOC: "Document",
    COMPARE_CURRENT: "(current versison)",
    CREATE_PROOFREADING: "Create a proofreading",
    SELECT_FLOW: "Select a flow type",
    PROOFREADING_MISSING: "No proofreading found",
    FLOW: "Flow",
    EXE: "EXE",
    REPRO: "REPRO",
    CREA: "CREA",
    HD: "HD",
    EZD_EXE: "EZD - EXE",
    EZD_PDF_REPOSITORY: "EZD - PDF",
    INFORMATIONS: "Information",
    READ_ONLY_APPROVER: "Approver in readonly mode",
    PRODUCTION_TEXTS: {
        ERROR_SAVING: "An error occurred while saving the text file",
        CREATE_MODIFY: "Edit / Create text file",
        INSTRUCTIONS: "Write the instructions",
        UPLOAD: "Text file submission",
        OPEN: "Open",
        NAME: "Text file",
        ADD: "Add a text file",
        ARE_YOU_SURE_ADD: "Do you want to add a text file?",
        ARE_YOU_SURE_REMOVE: "Do you want to remove this text field?",
        MANAGE_LANG: "Languages",
        PREFILL: "Prefill",
        ADD_FIELD: "Add a field",
        FIELD_NAME: "Field name",
        LABEL: "Label",
        FACING: "Facing",
        BACK: "Back + sides",
        FIELD_PLACEHOLDER: "Type the field name",
        LEGAL: "Legal texts",
        MARKETING: "Marketing texts",
        TVN: "Nutritional values",
        SAVED: "Text file saved",
        REMOVED: "Text field removed",
        PREFILL_SELECT_REF: "Please select a reference",
        PREFILL_SELECT_REF_TEXT: "This action will replace the content of the text file with that of the selected reference. Do you want to continue ?",
        PREFILL_SELECT_REF_PLACEHOLDER: "PXXXXXX"
    },
    TVN: {
        ADD_FIELD: "Add field"
    },
    SELECT_A_RANGE: "Select a brand",
    ERROR_FORM: " errors found in the form.\n Please correct them before submitting.",
    COMPARE_VERSION: "Version",
    COMPARE_RELEC: "Proofreading",
    COMPARE_RESET: "Reset the comparison",
    INTERN_REVISIONS: "Internal proofreadings",
    CLIENT_REVISIONS: "Customer proofreadings",
    INTERN_REVISIONS_INFO: "Internal",
    EXTERN_REVISIONS_INFO: "External",
    CLIENT_REVISIONS_INFO: "Proofreading",
    VERSION_REVISIONS_INFO: "Versions",
    NOT_AVAILABLE_YET: "Not available yet",
    MAIN_APPROVER: "Main approver",
    INFO_APPROVER: "Informative approver",
    OBSERVER_APPROVER: "Observer",
    GROUP_APPROVER: "Group",
    GROUP_MEMBER: "Group member",
    SELECT_APPROBATION_TYPE: "Please select group approval type",
    MEMBER_ALREADY_ADDED: "member(s) : ${ name } have been already added.",
    ONE_FOR_ALL: "Common decision",
    INDIVIDUAL: "Individual decision",
    FILE: "File",
    UPLOAD_FILE: "Upload file",
    UPLOAD_BRIEF: "Upload brief",
    OPTIONAL: "Optional",
    MEMBERS_STATUS: {
        NULL: "No approval",
        PAUSED: "Paused",
        PENDING: "No approval",
        ACCEPT: "Approved",
        REJECT: "Rejected",
        INPROGRESS: "In progress",
        READONLY: "Readonly",
        READONLYPROOFSCOPE: "Review"
    },
    REQUEST_STATUS_UPDATE: "Update request status",
    REQUEST_STATUS_UPDATE_CONFIRMATION: "Would you really update request status ?",
    REQUEST_STATUS_UPDATED: "Request status has been updated.",
    ED: {
        SELECT_REQUEST_TYPE: "Select your request type",
        SELECT_REQUEST_TYPE_PLACEHOLDER: "What can we do for you today?",
        EPSON: {
            PRINT_EPSON: "Epson print",
            QUANTITY: "Number of Epson prints",
            URL_SOURCE: "Url file",
            URL_TARGET: "Destination Epson", 
            URL_SOURCE_PLACEHOLDER: "File URL",
            URL_TARGET_PLACEHOLDER: "Hot Folder URL",
            ARE_YOU_SURE_PRINTING: "The project will be sent to print on ",
            ACTION: {
                PRINT: "Print",
                PRINT_PATHS: "Print paths"    
            },
            AVAILABLE_PR: {
                EXE: "EXE",
                REPRO: "REPRO",
                EZD_EXE: "EXE"
            }
        },
        ALL: "All",
        EDIT: "Edit request.",
        ALREADY_SENT: "Request already sent.",
        ARCHIVE: "Specify the relevant archive :",
        PROJECT_ARCHIVED: "Specify the project",
        GENERATE_ARCHIVE: "Generate archive",
        REGENERATE_ARCHIVE: "Regenerate archive",
        AVAILABILITY_DATE: "Disponibility date ",
        PLEASE_CHOOSE_TEXTS_TYPE: "Please choose how you would like to provide us with the new texts :",
        ZIP_TXT_DOC: "Please compile all of your request/text file into a single document (PPT/Word/Excel)",
        ZIP_PLAN_DOC: "Please compile your entire request into a single document (PDF)",
        INSTRUCTIONS: "You can provide the instructions for the texts here",
        ARE_YOU_SURE_CLOSE: "The request will be archived.",
        CLOSE: "Close the request",
        DASHBOARD: "Dashboard",
        MEMBERS_INDICATE: "Indicate the members who will review the references",
        ARE_YOU_SURE_DELETE: "Do you really want to close this request ?",
        REQUEST_DELETED: "The request has been deleted.",
        REQUEST_CREATE_ARCHIVE: "Create an archive",
        REQUEST_CREATE_ARCHIVE_CONFIRMATION: {
            "HERO-IMAGE": "An archive will be created and sent from the items in the '3. HD' subfolder.\n Please carefully check these items before proceeding.",
            "VISUALS-ISOLATION": "An archive will be created and sent from the items in the '3. HD' subfolder.\n Please carefully check these items before proceeding.",
            PACKSHOT: "An archive will be created and sent from the items in the '3. HD' subfolder.\n Please carefully check these items before proceeding.",
            "FILE-REDELIVERY": "An archive will be created and sent from the items in the 'z_Elements_divers/_LIVRAISON_[EXE ou REPRO]' subfolder.\n Please carefully check these items before proceeding."
        },
        SHOOTING: "Photo shoots",
        FINISH: {
            FINISH: "Finalization",
            COMM: "You can provide additional information here if you wish",
            TXT: "I understand that I take responsibility for the elements that will be approved by me before the delivery of the files.\n Diadem makes the requested corrections, but their validation and the proofreading process are entirely the customer's responsibility.",
            CONTACT_TXT: "I understand that this service will be entirely managed through the Easy Diadem platform, without direct contact with your usual contacts at Diadem. For any service request considered too complicated, or if contact is made other than through the Easy Diadem platform, Diadem reserves the right to issue an additional quote based on the complexity of the requested work"
        },
        FILES: {
            UPLOAD_SUCCESS: "The files have been successfully uploaded.",
            UPLOAD_FAILED: "An error occurred during the file upload."
        },
        REQUEST: {
            NAME: "EZD request",
            ERROR: "Une erreur est survenue lors de la création de la requête.",
            CREATED: "Your request has successfully been created.",
            SAVED: "Your form has been saved."
        },
        STATUS: {
            INIT: "Creating",
            PENDING: "Pending",
            PROJECT_LINKED: "Handled",
            WAITING_PRODUCTION: "Awaiting production",
            PRODUCTION: "In production",
            RELECTURE_IN_PROGRESS: "Proofreading in progress",
            VALIDATED: "Validated",
            UNVALIDATED: "Unvalidated",
            DELIVERING: "In delivery",
            DELIVERED: "Delivered",
            CLOSED: "Request closed."
        },
        VISUAL_ISOLATION_DESCRIPTION: "Please list the items to isolate (or upload a brief below)",
        ACTION: {
            CLOSE: "Close",
            DELIVERY: "Deliver"
        },
        BRIEFING: {
            REQUEST_UNCOMPLETE: "Incomplete request",
            REQUEST_UNCOMPLETE_TEXT: "Your request will be saved but will only be processed once the mandatory elements are provided.\n Please check the following items:",
            MISSING_SUBJECT: "Client Brief - Missing subject",
            MISSING_OTHER_SUBJECT: "Client Brief - Please specify the work to be done",
            MISSING_BRIEF_FILE: "Client Brief - Missing brief file",
            MISSING_TECHNICAL_NEW_REF_FILE: "Technical Plan - Missing technical plan file in case of new reference",
            MISSING_TECHNICAL_NO_INFO: "Technical Plan - Please indicate if you have any information on the technical plan",
            MISSING_TECHNICAL_UPDATE_REF_FILE: "Technical Plan - Missing technical plan file in case of reference update",
            MISSING_TEXT_SHEET_ARCHIVE: "Text Sheet - Missing archive name or number",
            MISSING_TEXT_SHEET_FILE: "Text Sheet - Missing text sheet file",
            TITLE: "Brief file",
            TYPE_DATAS: "Enter your data",
            TYPE_DATE: "Select a date using the calendar",
            MANDATORY: "Required",
            REQUIRED_FIELD: "Required field",
            OPTIONAL_FIELD: "Optional field",
            CUSTOMER_BRIEF: "Customer brief",
            COLOUR: "Color",
            CREA_FILES: "Creative files",
            IMAGES_TO_RETOUCH: "Images to be retouched",
            ARCHIVE_EXISTING_REFERENCE: "Existing reference archive",
            NUM_JOB_QUESTION: "What is the job number?",
            NUM_JOB_LABEL: "Job number",
            NUM_JOB_LABEL_PLACEHOLDER: "Enter the job number",
            TECHNICAL_PLAN: "Technical plan",
            TECHNICAL_PLAN_CONTENT: {
                TYPE: "Request type",
                NEW_REF_SHORT: "New reference(s)",
                UPDATE_REF_SHORT: "Update of one (or more) reference(s)",
                NO_INFO_SHORT: "No information yet",
                NEW_REF_TITLE: "Please upload the technical plan file(s) here",
                NEW_REF_SUBTITLE: "Note: PDF format (or .ai, .dxf)",
                NEW_REF_TITLE_2: "Or let us know if other references have the same plan",
                UPDATE_REF_TITLE: "Has the plan (or have the plans) changed compared to the archive?",
                OPTION_YES: "Yes",
                OPTION_NO: "No",
                UPDATE_REF_SUBTITLE: "Please upload the new technical plan file(s) here",
                NO_INFO_TITLE: "No information on this subject yet"
            },
            TEXT_SHEET: "Text file",
            TEXT_SHEET_CONTENT: {
                TITLE: "Where to retrieve the texts?",
                OPTION_ARCHIVE: "Retrieve the texts from the archive (Please fill in the field below)",
                OPTION_FILE: "Submission of a text file",
                OPTION_ONLINE: "Creation of an online text file",
                ARCHIVE_TITLE: "For an archive, please specify the relevant archive(s)",
                ARCHIVE_LABEL: "Name or ID of the archive(s)",
                FILE_TITLE: "For a submission, please upload the text file(s) here",
                FILE_SUBTITLE: "Note: PDF/Word/Excel/PowerPoint format"
            },
            PRINTING_METHOD: "Printing method & printer specifications",
            PRINTING_METHOD_SHORT: "Printing method",
            PRINTING_METHOD_CONTENT: {
                PRINTING_METHOD_LABEL: "If you know the printing method(s), please indicate them",
                PRINTING_METHOD_SHORT: "Printing method(s)",
                PRINTER_NAME_LABEL: "If you know the name of the printer, please indicate it",
                PRINTER_NAME_SHORT: "Name of the printer",
                SPECIFICATIONS_LABEL: "If you have the printer's specifications, please upload them here"
            },
            COLORING: "Coloring",
            COLORING_CONTENT: {
                TYPE: "What is the color scheme of your reference(s)?",
                NEW_REF_SHORT: "New reference(s)",
                UPDATE_REF_SHORT: "Update of one (or more) reference(s)",
                NO_INFO_SHORT: "No information yet",
                NEW_REF_TITLE: "Please indicate the determined color scheme (or the maximum number of colors allowed for printing)",
                UPDATE_REF_TITLE: "Has the color scheme changed compared to the archive?",
                OPTION_YES: "Yes",
                OPTION_NO: "No",
                UPDATE_REF_SUBTITLE: "Please indicate the determined color scheme (or the maximum number of colors allowed for printing)",
                NO_INFO_TITLE: "No information on this subject yet"
            },
            BRAND_CHART: "Brand guideline",
            BRAND_CHART_TITLE: "If you have a brand guideline document, please upload it here",
            BRAND_CHART_SUBTITLE: "Note: It is not necessary if there isn't one or if Diadem already has one",
            DEADLINE: "Deadline",
            DEADLINE_TITLE: "Please indicate the delivery deadline for the execution files",
            VALIDATION_CYCLE: "Validation cycle",
            VALIDATION_CYCLE_TITLE: "If you are using our LiveCo review platform, please list the people to be included in the validation cycle",
            CUSTOMER_CONTACT: "Customer contact",
            CUSTOMER_CONTACT_TITLE: "Please let us know who the customer contact is",
            SUBJECT_TITLE: "Customer brief",
            SUBJECT_LABEL: "What is the purpose of the requested work?",
            OTHER_TITLE: "Please specify:",
            OTHER_LABEL: "Work to be done",
            OTHER_SUBJECT_PLACEHOLDER: "Please describe the work",
            BRIEF_TITLE: "Brief preparation",
            BRIEF_LABEL: "If you have prepared a brief, please upload it here",
            COMMENT: "Additional information if needed",
            COMMENT_PLACEHOLDER: "Add a comment",
            REMARK: "Comments",
            CREA_FILES_TITLE: "If there are one or more new references, please upload the approved creative source files here",
            CREA_FILES_SUBTITLE: "Note: Assemble non-vectorized Illustrator source files with live text",
            IMAGES_TO_RETOUCH_TITLE: "If there are new images to retouch, please upload the source files here (Artwork or photo shoots)",
            IMAGES_TO_RETOUCH_SUBTITLE: "Note: Non-flattened Photoshop source images, processed in CMYK.",
            ARCHIVE_REF_TITLE: "If you have archives for your reference(s), please upload the existing files here",
            ARCHIVE_REF_SUBTITLE: "Note: Preferably non-vectorized Execution source files: Illustrator with live text, PDFs, and Photoshop files for images. At least, a PDF.",
            SUBJECTS: {
                UPDATE: "Update an existing reference",
                UPDATE_MULTIPLE: "Update multiple existing references",
                NEW_REF: "Execution of a new reference",
                NEW_MULTIPLE: "Execution of multiple new references",
                LOGO_EXE: "Logo execution",
                OTHER: "Other",
                EXE_ONLY: "Execution only",
                EXE_AND_RETOUCH: "Execution and image retouching",
                RETOUCH_ONLY: "Image retouching only",
                FILE_PREPARATION: "File preparation for mockups",
                ISOLATION: "Isolations (icons, logos, visuals...)"
            },
            NAME_QUESTION: "What is the name of the project or reference to be processed?"
        },
        WHAT_CAN_WE_DO: "What can we do for you today?",
        TASKS: {
            PACKSHOT: "Creation of a packshot",
            HERO_IMAGE: "Creation of a Hero image",
            "HERO-IMAGE": "Creation of a Hero image",
            NEW_ACCOUNT: "New account",
            "NEW-ACCOUNT": "New account",
            BRIEFING: "Customer Brief File",
            NEW_EPSON: "New Epson",
            "NEW-EPSON": "New Epson",
            VISUALS_ISOLATION: "Visual isolation",
            "VISUALS-ISOLATION": "Visual isolation",
            FILE_REDELIVERY: "File redelivery",
            "FILE-REDELIVERY": "File redelivery",
            TEXT_CORRECTION: "Simple text correction",
            "TEXT-CORRECTION": "Simple text correction",
            PLAN_CORRECTION: "Plan correction (without text correction)",
            "PLAN-CORRECTION": "Plan correction (without text correction)",
            TEXT_PLAN_CORRECTION: "Text and plan correction",
            "TEXT-PLAN-CORRECTION": "Text and plan correction",
            PDF_REPOSITORY: "PDF repository",
            "PDF-REPOSITORY": "PDF repository",
            NONE: "None"
        },
        REDELIVERY: {
            TXT: "I understand that the files will be available for a period of 30 days from the date of my request."
        },
        "PDF-REPOSITORY-TITLE": "PDF repository",
        "PDF-REPOSITORY": "PDF repository",
        "LINK-PROJECT": "Link the request to a project",
        WHAT_DO_YOU_WANT: "Please specify your request *",
        WHICH_PROJECT: "Which project do you want to isolate a visual from?",
        SEARCH_PROJECT: "Search for a project...",
        SEND_DEMAND: "Send the request",
        SAVE_DEMAND: "Save changes",
        LINK_PROJECT: "Link the request",
        LINK_USER: "Create account",
        PLAN_UPLOAD: "Plan upload",
        OTHER_TYPE_REQUEST: "Other type of request",
        NEW_REQUEST: "Create a new request",
        EDIT_REQUEST: "Edit the request",
        REQUEST_TYPE: "Request type",
        YOUR_DEMAND: "Your request",
        REQUEST_CREATE_PLACEHOLDER: "Select a request type to display and fill in the corresponding fields",
        HAS_BEEN_TAKEN_INTO_CONSIDERATION: "has been considered",
        AN_ERROR_OCCURED: "An error occurred",
        CLICK_HERE_TO_DROP_PDF: "Click here to drop your PDF",
        CLICK_HERE_TO_DROP_FILE: "Please click or drop your file here",
        CLICK_HERE_TO_DROP_FILES: "Please click or drop your files here",
        MAX: "Maximum",
        FILES_MIN: "files",
        ALLOWED_FORMATS: "allowed formats",
        FILE_DOWNLOAD: "File downloaded",
        PROJECT_LABEL: "Name your project",
        PROJECT_LABEL_PLACEHOLDER: "Project name",
        MEMBERS: "Members",
        ADD_MEMBER: "Add a member",
        STEP: {
            QUESTION: "Are the information below still accurate?",
            QUESTION_PLAN: "Apart from the plan, are the information below still accurate?",
            MEMBERS_TXT: "Specify the contacts who will proofread the references after corrections",
            COMM: "What changes need to be made?",
            PROOFREADING: "Edit/Create text file",
            INSTRUCTIONS: "Write instructions",
            UPLOAD: "Upload text files",
            TXT: "Please choose how you wish to provide the new texts:",
            PLAN: "Please choose how you wish to provide the new plan:"
        },
        TYPES: {
            "HERO-IMAGE": "Hero Image",
            "VISUALS-ISOLATION": "Visual isolation",
            "PDF-REPOSITORY": "PDF repository",
            "TEXT-CORRECTION": "Text correction",
            "PLAN-CORRECTION": "Plan correction",
            "TEXT-PLAN-CORRECTION": "Text and plan correction",
            "NEW-EPSON": "New Epson",
            PACKSHOT: "Creation of a packshot",
            "FILE-REDELIVERY": "File redelivery",
            "NEW-ACCOUNT": "New account",
            BRIEFING: "Customer Brief File"
        },
        DOWNLOAD: "Download the items",
        USERNAME_EXIST: "This name is already in use. Please choose another one",
        EMAIL_EXIST: "This email is already in use. Please choose another one",
        STEPPER: {
            INIT: {
                TITLE: "Order Pending Information",
                DESC: "Please enter the missing elements"
            },
            PENDING: {
                TITLE: "Order Received",
                DESC: "easyDiadem request received"
            },
            PROJECT_LINKED: {
                TITLE: "In Processing",
                DESC: "Request validated"
            },
            WAITING_PRODUCTION: {
                TITLE: "Awaiting Production",
                DESC: "Date to be determined",
                DESC_DATE: "Scheduled for %%DATE%%"
            },
            PRODUCTION: {
                TITLE: "In Production",
                DESC: "Request processed"
            },
            RELECTURE_IN_PROGRESS: {
                TITLE: "In Customer Review",
                DESC: ""
            },
            DELIVERING: {
                TITLE: "Preparing Delivery",
                DESC: "Preparing files"
            },
            DELIVERED: {
                TITLE: "Delivered",
                DESC: "Files delivered"
            },
            CLOSED: {
                TITLE: "Processing Completed",
                DESC: "The project has been completed"
            }
        }
    },
    QUANTITY: "Quantity",
    OLD_REF: "Old Reference",
    NEW_PROJECT: "New project created",
    PROOFREADING: "Proofreading",
    NEW_PROOFREADING: "New Proofreading",
    ADD_FIELD: "Custom Field",
    ADRESS: "Address",
    ALERT: {
        MODIF_DOC: "Warning, you will no longer be able to revert the document"
    },
    NO_PROOFREADING: "No Proofreading",
    APPROVAL: {
        APPROVED: "Your document has been approved",
        REJECT: "Your document has been rejected",
        TITLE: "Approval"
    },
    APPROVE: {
        REJECT: "Reject",
        VALID: "Approve"
    },
    APPROVERS: {
        DIALOG: {
            TEXT: "Warning, you will no longer be able to revert the document",
            TITLE: "Send Your Comments"
        }
    },
    BACK: "Back + Sides",
    CANCEL: "Cancel",
    CITY: "City",
    CODES: {
        ADRESS: "Address",
        CODE: "Code",
        CODES: "Gencod / QR Code",
        COLOR: "Color",
        CORRECT: "Correction",
        DATAMATRIX: "Datamatrix",
        NAMEMACHINE: "Name of Specification",
        ECOLOR: "Color",
        GENCOD: "Gencod",
        GENCODTAILLE: "Minimum Size",
        QRCODE: "QR CODE",
        RLB: "RLB",
        SENS: "Direction",
        TAILLE: "Modules / Minimum Sizes",
        TYPE: "Type",
        NAMESUPPORT: "Support Name"
    },
    COLORS: {
        MANAGE: "Manage Colors",
        COLOR: "Color",
        COLORS: "Coloring",
        COMM: "Printer Specifications",
        COLORMAX: "Maximum Number of Colors",
        MECREEL: "Number of Colors Used",
        PRINTCOLOR1: "Color 1",
        PRINTCOLOR10: "Color 10",
        PRINTCOLOR2: "Color 2",
        PRINTCOLOR3: "Color 3",
        PRINTCOLOR4: "Color 4",
        PRINTCOLOR5: "Color 5",
        PRINTCOLOR6: "Color 6",
        PRINTCOLOR7: "Color 7",
        PRINTCOLOR8: "Color 8",
        PRINTCOLOR9: "Color 9",
        PRINTFINITION1: "Finish 1",
        PRINTFINITION10: "Finish 10",
        PRINTFINITION2: "Finish 2",
        PRINTFINITION3: "Finish 3",
        PRINTFINITION4: "Finish 4",
        PRINTFINITION5: "Finish 5",
        PRINTFINITION6: "Finish 6",
        PRINTFINITION7: "Finish 7",
        PRINTFINITION8: "Finish 8",
        PRINTFINITION9: "Finish 9",
        PRINTFINITIONS: "Finishes",
        PRINTSEQ: "Print Sequence",
        REFS: "References",
        SAMECOLORS: "Common Colors",
        VALIDATORS: {
            NUMERIC: "Please enter a number"
        }
    },
    COLORBOOKS: {
        COLORBOOKS: "Color Swatches",
        FINISHCOLORS: "Finish Swatches",
        VALIDATORS: {
            COLORS: "Please enter a color name",
            CATEGORIES: "Please select a category"
        },
        CATEGORIES: "Color Categories",
        COLORS: "Colors to Add",
        PLACEHOLDER: "PANTONE 100 C, PANTONE 101 C, PANTONE 102 C, PANTONE 103 C, PANTONE 104 C, PANTONE 105 C, PANTONE 106 C...",
        UPDATE: "The color palette has been updated",
        CREATE: "The color palette has been created",
        DIALOG: {
            EXIST: "The following colors already exist in this category (they will not be created)"
        }
    },
    COMM: "Comments",
    COMM_SUBMIT: "Comments saved",
    CONSTRAINTS: "Technical Constraints",
    CONTACT: "Contact",
    CREATE: "Create",
    CREATE_CHILD: "+ Child Project",
    CREATE_DIALOG: {
        TEXT: "Send to customer?",
        TITLE: "Text File"
    },
    CREATE_MASTER: "+ Parent Project",
    CUSTOM_FIELDS: "Custom Fields",
    CUSTOMER: {
        SEARCH: "Search for a customer..."
    },
    MEMBERS: "Members",
    CONTROL: "Control",
    DASHBOARD: {
        BRIEF: "Brief File",
        SPLITVIEW: "Customer Review",
        SPLITVIEW_INTERN: "Internal Review",
        FOLDER: "Open Folder",
        PACKZ: "Open with PacKZ",
        UPDATE_JOB: "Edit Project",
        HISTORY: "Review History",
        CONTROL_INTERN: "Internal Review",
        COMPARE: "Compare Versions",
        PREVIEW: "Preview",
        SET_READY: "Job is Ready",
        REFRESH: "Refresh View",
        FULLSCREEN: "Fullscreen",
        STATS: 'KPI',
    },
    STATUS: "Status",
    DESCRIPTION: "DESCRIPTION",
    DIALOG: {
        APPROVE: {
            REJECT: "Reject",
            TEXT: "",
            TITLE: "Document Approval",
            VALID: "Approve"
        },
        ASSESSMENT: {
            TEXT: "",
            TITLE: "Document Approval",
            VALID: "Approve",
            APPROBATION: "Approval",
            REJECT: "Reject",
            APPROBATION_TXT: "Do you confirm the approval of the document?",
            REJECT_TXT: "Do you confirm the rejection of the document?"
        },
        PAUSE: {
            TITLE: "Pause",
            TEXT: "Pause the ongoing review"
        },
        RESTART: {
            TITLE: "Restart Cycle",
            TEXT: "Restart the ongoing review"
        }
    },
    DOCUMENT_READONLY: "Read Only",
    DOCUMENT_READONLY_PROOFSCOPE: "Consultation",
    READONLYPROOFSCOPE: "Consultation",
    READONLY: "Read Only",
    PENDING: "Pending",
    DOCUMENT_PENDING: "Pending",
    DOCUMENT_APPROVE: "Document Approved",
    ACCEPT: "Document Approved",
    DOCUMENT_REJECT: "Document Rejected",
    REJECT: "Document Rejected",
    "INPROGRESS-R": "In Processing",
    INPROGRESS: "In Processing",
    EMAIL: "Email",
    ERROR_CLIENTONLY_ATTRIBUTES_NAME: "A customer cannot be member of DIADEM",
    ERROR_MISSING_ATTRIBUTES_NAME: "Please select a role",
    ERROR_MISSING_MARQUE_FOR_CUSOMER: "Please select a brand",
    ERROR: {
        NODATA: "Unable to retrieve data",
        NOREVISIONS: "No proofreading for this project",
        NOSUBMIT: "Unable to submit data",
        NO_EDIT_MASTER: "Cannot modify parent project data",
        ONLYCPPCAN: "Only CPPs are allowed to access this page",
        ONLYDIADEMCAN: "Only Diadem employees are allowed to access this page",
        MUST_BE_IN_LIST: "Selected entry must be in the list",
        L2PUB_PROJECTS: "Error while retrieving L2PUB projects",
    },
    NEW_PLAN: "New Plan",
    FACING: "Facing",
    FIELD_NAME: "Field Name",
    FILETMINNEG: "Minimum Negative Bleed",
    FILETMINPOS: "Minimum Positive Bleed",
    GO_TO_HOME: "Menu",
    ERRORS: {
        QUANTITY: "Please enter a valid quantity 1 - 5",
        REQUIRED_EMAIL: "Please enter a valid email address",
        TEL: "Please enter a valid phone number",
        CONTACT: "Please indicate an address",
    },
    HOME: {
        SPLIT_VIEW: "Proofreading",
        SPLIT_VIEW_INTERN: "Internal Proofreading",
        ARCHIVE: "Archive",
        ARCHIVED: "Archived",
        COLORBOOKS: "Color Swatches",
        DISARCHIVE: "Unarchive",
        OPEN_FOLDER: "Open Folder",
        BRIEF: "Brief File",
        CREATE: "Text File",
        CREATE_CHILD: "Child Project",
        CREATE_MASTER: "Parent Project",
        CREATE_PRINTER: "Create a Printer",
        CREATE_PROJECT: "Create a Project",
        CREATE_USER: "Add a User",
        EDIT_PROJECT: "Edit Project",
        FORM: "Text File",
        KIOSK: "Cloudflow",
        MENU: "Menu",
        PRINTER: "Printer",
        HOME: "Home",
        PROJECT: "Project",
        SPLITVIEW: "Customer Proofreading",
        TABLE_PROJECT: "Projects",
        TABLE_PRINTER: "Printers",
        TABLE_USER: "Users",
        UPDATE: "Edit Text File",
        USERS: "Users",
        ACCOUNT: "My Account"
    },
    INFO: "General Information",
    MINTXTNEG: "Minimum Negative Text",
    MINTXTPOS: "Minimum Positive Text",
    NAME: "Name",
    NOPERMISSION: {
        TEXTAPPRO: "Texts sent to approvers",
        TEXTOK: "Texts are already OK",
        TITLE: "No Permission",
        TITLE_TEXTOK: "No Permission"
    },
    NOREF: "No Reference Available",
    NORESULT: "No Result",
    NUTRITABLE: {
        AR_PORTION: "% AR / portion",
        FOR_100: "100",
        FOR_100_UNIT: "g/ml",
        FOR_PORTION: "One portion of",
        UNIT_G: "(g)",
        UNIT_KCAL: "(Kcal)",
        UNIT_KJ: "(kJ)",
        VALEUR_MOYENNES: "Average values for:"
    },
    OK: "Ok",
    ORIENTATION: "Orientation",
    PAYS: "Country",
    PC_EDIT_SUCESS: "Your changes have been saved",
    POSTALCODE: "Postal Code",
    PREFILL_JOB_CHILD_REFERENCE: "Pre-fill child project fields",
    PREFILL_JOB_MASTER_REFERENCE: "Pre-fill parent project fields",
    UPDATE_DATE: "Update Date",
    PAUSE: "Pause",
    PASSWORD: "Password",
    HOST: 'Host',
    PORT: 'Port',
    REMOTE_PATH: 'Remote Path',
    CONNECTION: "Connection",
    PRINTER: {
        PRINTING_METHOD: "Printing method",
        ADD_CONTACT: "Add a Contact",
        PREFILL_PRINTER: "Pre-fill printer fields",
        SELECT_PRINTER: "Select a Printer",
        ARE_YOU_SURE_DELETE_PRINTER_TEXT: "The printer will be deleted",
        NEW_PRINTER: "New Printer",
        CREATE_PRINTER: "Create a New Printer",
        COLLAGE: "Collage Area",
        COLORDEBDEV: "Color Bleed - Development",
        COLORDEBLAIZE: "Color Bleed - Width",
        CREATE: "Create Printer",
        EDIT_SUCESS: "Your changes have been saved",
        FILESEND: "File Delivery",
        MACHINE: "Machine",
        PRINT: "Print",
        PRINTER: "Printer",
        PRINTERS: "Printers",
        SENSIMPRESSION: "Print Direction",
        PROCESS: "Printing Process",
        REPRO: {
            CONSTRAINTS: "Constraints",
            CHROMIE: {
                CHROMIE: "Chromie",
                CIBLE: "Provided Color Target",
                ENCR: "Maximum Ink",
                PTMAX: "Max Dot",
                PTMIN: "Min Dot",
                REPROPROFIL: "Profile",
                TRAM: "Screen",
                STEPREUVAGE: "Proofing Standard"
            },
            EPREUVAGE: "Proofing",
            FILE1: "Files to Provide",
            FILE2: "PSD",
            OPERATOR: "REPRO Graphic Designer",
            REPRO: "REPRO",
            RETRAIT: {
                BLANC: "White Removal",
                COULEUR: "Color Removal",
                VERNIS: "Varnish Removal"
            },
            TRAP: "Trapping",
            TRAP_RETRAIT: "Trapping and Removal"
        },
        SECUCOUPE: "Cut Security",
        SECURAIN: "Slitter Security",
        SEND: "File Delivery",
        SPECIFICATION: "Specification Location",
        SPOT: "Spot Reading",
        SUPPORT: "Support"
    },
    FINISHBOOKS: {
        COLORS: "Please enter a finish name",
        PLACEHOLDER: "VARNISH, MAT VARNISH, GLOSS VARNISH..."
    },
    HISTORY: "History",
    INTERN_HISTORY: {
        NONE: "Creation",
        "CHECK-AUTO-CONTROL": "Auto-Control",
        READY: "Ready",
        "CHECK-QUALITY": "Quality Control",
        "CHECK-REPRO": "Repro Control",
        "CHECK-PROD": "Graphic Control",
        "PROD-INTERN-REJECTED": "Auto Control Rejected",
        "CPP-INTERN-REJECTED": "CPP Control Rejected",
        "CHECK-CPP": "CPP Control",
        "WAITING-AUTO-CONTROL": "Modifications (Auto-Control)",
        "WAITING-CHECK-CPP": "Modifications (CPP Control)",
        "INTERN-FINISHED": "CPP Control Validated",
        "INTERN-FINISHED-OPERATOR": "Ope. Control Validated",
        CONTROL_APPROVED: "Control Approved",
        CONTROL_REJECTED: "Control Not Approved",
        WIP: "In Production",
        "CONTROL-VALIDATED": "Control Validated",
        "CONTROL-FAILED": "Control Failed",
        GENERATING: "Generating",
        "PR-CLIENT-INPROGRESS": "Sent to Customer"
    },
    EXTERN: {
        "SEND-PR-CLIENT": "Text Verification by Customer",
        "SEND-PR-CPP": "Text Verification by Project Manager",
        "IN-PROGRESS": "In Approval",
        REJECTED: "Document Rejected by Project Manager",
        APPROVED: "Document Approved by Project Manager",
        "FEEDBACK-INFO": "Document Reviewed by Info Approvers",
        "FEEDBACK-CLIENT": "Document Reviewed by Document Approver",
        PAUSED: "Proofreading Paused",
        DELIVERED: "Delivered",
        "POST-VALIDATION": "Post-Validation",
        INIT: "Waiting for Launch",
        "IN-PRODUCTION": "In Production",
        WAIT_YOUR_TURN: 'Please wait for your turn, current approbation order is : ',
        RESTART: "Restart",
        BTN: {
            "IN-PRODUCTION": "Send to Production",
            "SEND-PR-CLIENT": "Send to Customer",
            "SEND-PR-CPP": "Send to Project Manager",
            "IN-PROGRESS": "Send to approval",
            REJECTED: "Reject",
            APPROVED: "Approve",
            "FEEDBACK-INFO": "Document Reviewed by Info Approvers",
            "FEEDBACK-CLIENT": "Document Reviewed by Document Approver",
            PAUSED: "Pause",
            RESTART: "Restart",
            DELIVERED: "Delivery",
            "POST-VALIDATION": "Post-Validation",
            INIT: "Waiting for Launch"
        },
        DASHBOARD: {
            NULL: "No internal proofreading",
            "SEND-PR-CLIENT": "Customer Proofreading",
            "SEND-PR-CPP": "CPP Proofreading",
            INIT: "Waiting to start",
            "IN-PROGRESS": "Awaiting approval",
            REJECTED: "Rejected",
            APPROVED: "Approved",
            PAUSED: "Paused",
            "FEEDBACK-INFO": "Info appro.",
            "FEEDBACK-CLIENT": "Customer appro.",
            DELIVERED: "Delivered",
            "POST-VALIDATION": "Post-Validation",
            "IN-PRODUCTION": "In production"
        },
        DIALOG: {
            "IN-PRODUCTION": "Do you want to send the proofreading to production?",
            "SEND-PR-CLIENT": "Send proofreading to the customer?",
            "SEND-PR-CPP": "Validate proofreading with the project manager",
            "IN-PROGRESS": "In approval process",
            REJECTED: "Do you want to reject the document?",
            APPROVED: "Do you want to approve the document?",
            PAUSED: "Pause proofreading?",
            "FEEDBACK-INFO": "Do you want to submit your approval?",
            "FEEDBACK-CLIENT": "Do you want to submit your approval?",
            DELIVERED: "Do you want to deliver the job?",
            "POST-VALIDATION": "Do you want to return the job to production?",
            RESTART: "Do you want to restart the approval?",
            INIT: "Do you want to return the job to waiting to start ?",
        },
    },
    INTERN: {
        SELECT_FLOW: {
            TITLE: "Select Workflow",
            DESC: "Please select the workflow"
        },
        WARN_PROD: {
            TITLE: "Warning!",
            RELECTURE: "You are in proofreading: ",
            DESC: "Complete your pack and fill in the information in the cartridge tab of your job in Liveco 4.0"
        },
        TODO: {
            EXE: "EXE",
            REPRO: "REPRO",
            EZD_EXE: "EZD - EXE",
            INFO_CARTOUCHE: "Cartridge Info",
            REPRO_FILES: "REPRO File",
            EXE_FILES: "Native File",
            IMAGE: "Images",
            PSD: "PSD",
            VECT_PDF: "Vector PDF",
            PDF_BD: "Low-Def PDF",
            VECT_ILLUSTRATOR: "Vector Illustrator",
            DAM_DELIVERY: "DAM Delivery",
            NOT_VECT_ILLUSTRATOR: "Non-Vector Illustrator",
            NOT_VECT_PDF: "Non-Vector PDF",
            FONTS: "Font Assembly + Font Disclaimer",
            PREPA_3D: "3D Preparation",
            DELIVERY_TEMPLATE: "Delivery Template",
            FTP_DEPOSIT: "FTP Deposit",
            PACKZ: "PACKZ",
            AI: "AI",
            ARTPRO: "Artpro",
            ARTPRO_PLUS: "Artpro +",
            PDF_HD: "PDF HD",
            PSD_PHOTOGRAVURE: "PSD Photogravure",
            PNG_BD: "PNG BD",
            PNG_HD: "PNG HD",
            JPG_BD: "JPG BD",
            JPG_HD: "JPG HD",
            FILE:"Files to Provide",
            JPG_PNG_FACING_HD:"JPG / PNG FACING HD"
        },
        WARN_DELIVERY: {
            TITLE: "Check Before Delivery!",
            RELECTURE: "You are in proofreading: ",
            DESC: "Check for the presence of all necessary files for delivery before zipping. Assemble using Illustrator if necessary at this stage."
        },
        UNABLE_TO_SUBMIT: "Unable to submit request",
        UNABLE_TO_SUBMIT_TEXT: "If this is EXE proofreading, make sure you have created the text file and that it has also been sent.",
        UPDATE_DOC: "Self-check",
        NONE: "Brief in progress",
        READY: "Ready",
        "CHECK-AUTO-CONTROL": "Self-check",
        "CHECK-QUALITY": "Quality Check",
        "CHECK-REPRO": "REPRO Check",
        "CHECK-PROD": "Graphic Check",
        "CHECK-CPP": "CPP Check",
        GENERATING: "Generating",
        "PROD-INTERN-REJECTED": "Self-check rejected",
        "CPP-INTERN-REJECTED": "CPP Check rejected",
        WIP: "In production",
        "CONTROL-VALIDATED": "Check validated",
        "CONTROL-FAILED": "Check failed",
        CONTROL_APPROVED: "Check validated",
        CONTROL_REJECTED: "Check not validated",
        CONTROL_PENDING: "Awaiting proofscope",
        "WAITING-AUTO-CONTROL": "Document awaiting modifications (self-check)",
        "WAITING-CHECK-CPP": "Document awaiting modifications (CPP Check)",
        "INTERN-FINISHED": "CPP Control validated",
        "INTERN-FINISHED-OPERATOR": "Ope. Control Validated",
        "RE-DELIVER": "Re-delivery",
        PR_CLIENT_INPROGRESS: "Customer delivery",
        "PR-CLIENT-INPROGRESS": "Sent to customer",
        "TO-DELIVER": "To deliver",
        "CONTROL-DELIVERY-VALIDATED": "Delivery validated",
        "CONTROL-DELIVERY-FAILED": "Delivery failed",
        DELIVERED: "Delivery completed",
        "SWITCH-TO-REPRO": "Switch to REPRO",
        BTN: {
            NEXT: {
                NONE: "Initialized",
                READY: "Ready",
                "CHECK-AUTO-CONTROL": "Self-check",
                "CHECK-QUALITY": "Quality Check",
                "CHECK-REPRO": "REPRO Check",
                "CHECK-PROD": "Graphic Check",
                "CHECK-CPP": "CPP Check",
                RETURN_TO: "Return to",
                CONTROL_APPROVED: "Check validated",
                CONTROL_REJECTED: "Check not validated",
                CONTROL_PENDING: "Awaiting proofscope",
                "WAITING-AUTO-CONTROL": "Return to production",
                "WAITING-CHECK-CPP": "Return to production",
                "INTERN-FINISHED": "CPP Control validated",
                "INTERN-FINISHED-OPERATOR": "Ope. Control Validated",
                PR_CLIENT_INPROGRESS: "Customer delivery",
                "PR-CLIENT-INPROGRESS": "Send to customer",
                "PROD-INTERN-REJECTED": "Self-check rejected",
                "CPP-INTERN-REJECTED": "CPP Check rejected",
                "CONTROL-VALIDATED": "Check validated",
                "CONTROL-FAILED": "Check failed",
                WIP: "In production",
                "TO-DELIVER": "To deliver",
                "CONTROL-DELIVERY-VALIDATED": "Validate delivery",
                "CONTROL-DELIVERY-FAILED": "Reject delivery",
                DELIVERED: "Finalize delivery",
                "RE-DELIVER": "Re-delivery",
                "SWITCH-TO-REPRO": "Switch to REPRO"
            },
            PREVIOUS: {
                NONE: "Initialize",
                READY: "Reset",
                "CHECK-AUTO-CONTROL": "Self-check",
                "CHECK-QUALITY": "Quality Check",
                "CHECK-REPRO": "REPRO Check",
                "CHECK-PROD": "Graphic Check",
                "CHECK-CPP": "CPP Check",
                RETURN_TO: "Return to",
                CONTROL_APPROVED: "Check validated",
                CONTROL_REJECTED: "Check not validated",
                CONTROL_PENDING: "Awaiting proofscope",
                "WAITING-AUTO-CONTROL": "Return to production",
                "WAITING-CHECK-CPP": "Return to production",
                "INTERN-FINISHED": "CPP Control validated",
                "INTERN-FINISHED-OPERATOR": "Ope. Control Validated",
                PR_CLIENT_INPROGRESS: "Customer delivery",
                "PR-CLIENT-INPROGRESS": "Sent to customer",
                "PROD-INTERN-REJECTED": "Self-check rejected",
                "CPP-INTERN-REJECTED": "CPP Check rejected",
                "CONTROL-VALIDATED": "Check validated",
                "CONTROL-FAILED": "Check failed",
                WIP: "Return to production",
                "TO-DELIVER": "Reset delivery",
                "RE-DELIVER": "Re-delivery",
                "CONTROL-DELIVERY-VALIDATED": "Return to validated delivery",
                "CONTROL-DELIVERY-FAILED": "Return to rejected delivery",
                DELIVERED: "Finalize delivery",
                "SWITCH-TO-REPRO": "Switch to REPRO"
            }
        },
        DASHBOARD: {
            NULL: "No internal proofreading",
            NONE: "Brief in progress",
            UPDATE_DOC: "Document modified",
            READY: "Ready",
            "CHECK-AUTO-CONTROL": "Self-check",
            APPROVED: "Approved",
            REJECTED: "Rejected",
            "CHECK-QUALITY": "Quality Check",
            "CHECK-REPRO": "REPRO Check",
            "CHECK-PROD": "Graphic Check",
            "CHECK-CPP": "CPP Check",
            WIP: "In production",
            CONTROL_APPROVED: "Check validated",
            CONTROL_REJECTED: "Check not validated",
            CONTROL_PENDING: "Awaiting proofscope",
            "WAITING-AUTO-CONTROL": "Modifications for self-check",
            "WAITING-CHECK-CPP": "Modifications for CPP Check",
            "INTERN-FINISHED": "CPP Control validated",
            "INTERN-FINISHED-OPERATOR": "Ope. Control Validated",
            "PROD-INTERN-REJECTED": "Self-check rejected",
            "CPP-INTERN-REJECTED": "CPP Check rejected",
            PR_CLIENT_INPROGRESS: "Customer delivery",
            "PR-CLIENT-INPROGRESS": "Sent to customer",
            GENERATING: "Generating",
            RELECTURE_IN_PROGRESS: "Customer proofreading in progress",
            "TO-DELIVER": "To deliver",
            "CONTROL-DELIVERY-VALIDATED": "Delivery validated",
            "CONTROL-DELIVERY-FAILED": "Delivery failed",
            DELIVERED: "Delivery completed",
            "RE-DELIVER": "Re-delivered",
            "SWITCH-TO-REPRO": "Switch to REPRO"
        },
        APPROVE: {
            NONE: "Brief in progress",
            READY: "Document ready for production.",
            CONTROL_TO_CPP: "To be validated by the CPP",
            CONTROL_TO_QUALITY: "To be validated by Quality Control",
            CPP: "CPP validated",
            "INTERN-FINISHED": "Do you want to validate the CPP Control?",
            "INTERN-FINISHED-OPERATOR": "Do you want to validate the Ope. Control?",
            "PR-CLIENT-INPROGRESS": "Send proofreading to the customer",
            "CHECK-AUTO-CONTROL": "Document modified, to be validated in self-check",
            "CHECK-QUALITY": "Document OK, to be validated by Quality Control",
            "CHECK-REPRO": "Document OK, to be validated by REPRO Check",
            "CHECK-PROD": "Document OK, to be validated by Graphic Check",
            "CHECK-CPP": "Document OK, to be validated by CPP",
            "PROD-INTERN-REJECTED": "Reject self-check",
            "CPP-INTERN-REJECTED": "Reject CPP Check",
            WIP: "Move job to production",
            "CONTROL-FAILED": "Document checked, declared invalid.",
            "CONTROL-VALIDATED": "Document checked, declared valid.",
            "TO-DELIVER": "Initialize delivery",
            "CONTROL-DELIVERY-VALIDATED": "Return to valid delivery",
            "CONTROL-DELIVERY-FAILED": "Delivery is non-compliant",
            DELIVERED: "Check to see if everything went well! If so, proceed; otherwise, reset the delivery.",
            "RE-DELIVER": "Do you want to re-deliver the job?",
            "SWITCH-TO-REPRO": "Do you want to switch to REPRO?"
        },
        REJECT: {
            QUALITY: "Quality control not validated",
            GRAPHIC: "Graphic control not validated",
            OPERATOR: "Self-check not validated",
            REJECT: "Control not validated",
            DOCUMENT: "Document to be modified",
            "CHECK-CPP": "Document to be validated by CPP"
        }
    },
    LINK_MASTER_REFERENCE: "Link between parent and child project",
    MAIL: "Email",
    TABLE_PROJECT: "My projects",
    TABLE_REQUESTS: "My requests",
    MENU: {
        USERS: "Users",
        GROUPS: "Groups",
        MY_ACCOUNT: "My account",
        PROJECTS: "Projects",
        LOG_OUT: "Log out",
        COLORS: "Color charts",
        COLORBOOKS: "Color chart (colors)",
        FINISHBOOKS: "Color chart (finishes)",
        OPEN_FOLDER: "Open folder",
        BRIEF: "Brief",
        CREATE: "Create text file",
        CREATE_CHILD: "Create child project",
        CREATE_MASTER: "Create parent project",
        CREATE_PRINTER: "Create printer",
        CREATE_PROJECT: "Create project",
        CREATE_REQUEST: "Create request",
        CREATE_USER: "Create user",
        EDIT_PROJECT: "Edit child project",
        FORM: "Text files",
        KIOSK: "Cloudflow",
        PATCH_NOTES: "Patch notes",
        MANAGE: "Manage",
        MARQUES: "Brands",
        MENU: "Menu",
        EASYDIADEM: "EasyDiadem",
        PRINTER: "Printers",
        HOME: "Home",
        PROJECT: "Project",
        SPLITVIEW: "Customer proofreading",
        TABLE_PROJECT: "My projects",
        TABLE_PRINTER: "Printers",
        TABLE_USER: "Users",
        UPDATE: "Edit text file",
        ACCOUNT: "My account",
        STATS: "KPI",
        ED: {
            ED_REQUESTS: "My requests",
            NEW_REQUEST: "New request"
        }
    },
    PREVIEW_CARTOUCHE: {
        NUM_JOB: "Job number",
        RANGE: "Brand",
        FOLDER: "Folder",
        DATE: "Date",
        REPRO_OPERATOR: "REPRO Graphic Designer",
        EXE_OPERATOR: "EXE Graphic Designer",
        IMAGE: "Image imports",
        GENCOD: "Gencod",
        PRINTER: "Printer",
        PRINT: "Printing",
        PLAN: "Plan",
        SUPPORT: "Support",
        PROFIL: "Profile"
    },
    PROJECT: {
        RESET_MASTER_JOB: "Change the master project",
        COLORS: "Coloring",
        ALL_CUSTOMER: "All customers",
        NEW_MASTER_PROJECT: "New master project",
        EDIT_MASTER_PROJECT: "Edit master project",
        NEW_PROJECT: "New projet",
        EDIT_PROJECT: "Edit projet",
        JOB_FLUX_REQUIRED: "Job flow is required",
        CREATED: "Created project",
        EDITED: "Saved project",
        PDF_REPO: "PDF Repository",
        MEMBERS: {
            LABEL: "Members",
            TITLE: "Add members...",
            FILTER_PLACEHOLDER: "Search for a user...",
            TABLE: {
                NAME: "NAME",
                PROOFSCOPE: "PROOFSCOPE",
                TEXTS: "TEXTS",
                APPROVERS: "APPROVERS",
                VERSIONS: "VERSION/OPINION",
                EMAIL: "EMAIL",
                ORDER: "ORDER",
                READ: "Read",
                WRITE: "Write",
                INFO: "Info",
                PREEMPTIVE_RIGHT: "PREEMP.",
                PREEMPTIVE_RIGHT_LONG: "Preemptive right in case of refusal",
                DOCUMENT: "Document",
                ERROR: {
                    APPRODOC: "• No member is designated as document approver",
                    ATLEASTONEINFO: "• At least one approver for information required",
                    MAXIMUMONE: "• There can be only one document approver"
                }
            }
        },
        CART: {
            CART: "Cartouche",
            FILETMINNEG: "Minimum negative border",
            FILETMINPOS: "Minimum positive border",
            HAUTCHIFFREPOIDS: "Height of weight/volume numbers",
            HAUTEMETRO: "Height E Metrological",
            HAUTXMIN: "Height of x lowercase",
            MINTXTNEG: "Minimum negative text",
            MINTXTPOS: "Minimum positive text",
            POLICEUSED: "Font used",
            PREFILL: "Pre-fill cartouche",
            SHOW_PREVIEW: "Regenerate preview",
            SHOW_PREVIEW_EXE: "EXE Cartouche",
            SHOW_PREVIEW_REPRO: "REPRO Cartouche",
            GENERATE_PREVIEW: "Generate cartouche",
            GENERATING_FILE: "Generating file",
            ERROR_GENERATING_FILE: "Error generating file"
        },
        CHILD: "Child project",
        COMM: {
            CHROM: "Chromie Comment",
            COMM: "Comments",
            CPP: "CPP Comment",
            GAMME: "Range Comment",
            HD: "HD Comment",
            IMP: "Printer Comment",
            PREPA: "Preparation Comment"
        },
        CREA: {
            COMM: "Comment",
            CREA: "Creation",
            INFO: "Information"
        },
        CREATE: "Create project",
        CREATED_AT: "created on",
        EXE: {
            CUSTOMER_COMMENT: "Customer comment",
            BOOKPACK: "Bookpack",
            CHARTE: "Customer Charter",
            COMM_CPP: "CPP Comment",
            BRIEF: "Exe Brief",
            DEF: "Definition",
            EXE: "Exe",
            IMAGE: "Image",
            LANGUES: "Language Order",
            LOGO: "Logo to execute",
            NB_LANGUES: "Number of languages",
            OPERATOR: "Exe Graphic Designer",
            SOFT: "Software",
            TEXTS: {
                ARCHIVE: "Archive",
                BRIEF: "Customer/Team Brief",
                CLIENT: "Customer Text File",
                CREA: "Creation",
                TEXTS: "Texts"
            }
        },
        FILTERS: {
            OLD_PROJECTS: "Old projects",
            PROJECTS_ARCHIVED: "Archived projects",
            IN_APPROVAL_STATUS: "Awaiting validation",
            DISPLAY_CLOSED: "Show processed requests",
            MY_PROJECTS: "My projects",
            PROJECTS: "Ongoing projects",
            DATE: "Date",
            DATES: "Dates",
            DATE_RANGE: {
                START_DATE: "Start",
                END_DATE: "End"
            },
            DATE_RANGE_ERRORS: {
                START_DATE: "Start",
                END_DATE: "End"
            },
            RESET: "Reset",
            SEARCH: "Start search",
            REQUEST_TYPE_LABEL: "Request type",
            REQUEST_TYPE: {
                NONE: "None",
                VISUALS_ISOLATION: "Visual isolation",
                PDF_REPOSITORY: "PDF repository"
            },
            REDELIVERY_TYPE_LABEL: "Redelivery type",
            REDELIVERY_TYPE: {
                EXE: "EXE",
                REPRO: "REPRO",
                EZD_PDF_REPOSITORY: "EZD PDF repository"
            }
        },
        HD: {
            ARCHIVE: "Archive recovery",
            COMMCUSTOMER: "HD Brief",
            ELEMENTS: "Elements for HD",
            HD: "HD",
            HDSHOOT: "Shutter",
            OPERATOR: "HD Graphic Designer",
            PLANNING: {
                EPSON: "Epson 1 Customer / TC",
                LIVRAISON: "Delivery",
                PLANNING: "Planning",
                R0: "R0 Master",
                R1: "R1 Master"
            },
            SHOOTCUSTOMER: "Customer Shoot",
            SHOOTER: "HD Shoot"
        },
        INFO: {
            DECOR_TYPE: "decor type",
            FRONT_ONLY: "Front only",
            FRONT_AND_REVERSE: "Front and reverse",
            FLOWS: "Flow",
            AGENCE: "Agency",
            INFO_MASTER: "Master project generales infos",
            APPROVER1: "Approver 1",
            APPROVER2: "Approver 2",
            APPROVER3: "Approver 3",
            APPROVER4: "Approver 4",
            APPROVERS: "Approvers",
            MEMBERS: "Members",
            ARCHIVE: "Archive",
            BASE: "Work Base",
            CHILD: "Child Number",
            CODEARTICLE: "Article Code",
            CONTACT: "Contact",
            CONTRAINTE: "Technical Constraints (printer)",
            CPP: "CPP",
            CREA: "Creation",
            CREA_NAME: "Name + Creation Number",
            CREA_TYPE: "Creation Type",
            CUSTOMER: "Customer",
            CUSTOMER_INVOICE: "Customer Billing (optional)",
            CUSTOMER_CODE: "Customer Code",
            DATEVALID: "Validation Date",
            DESIGNATION: "Designation",
            DESIGNATIONGAMME: "Designation",
            DESIGNATION_PRODUIT: "Product Designation",
            FAMILY: "Family",
            FILETMINN: "Minimum Negative Border",
            FILETMINP: "Minimum Positive Border",
            IDENTIFIANT: "Identifier",
            IDENTIFIER: "Child Job Number",
            IDMAITRE: "Master Job Number",
            INFO: "General Information",
            LANGUAGES: "Languages",
            MASTER: "Master Number",
            NUMCREA: "Validated Creation Number",
            NUMDIADEM: "Diadem Number",
            NUMTEAM: "TeamCreatif Number",
            OPERATOR: "Reference Graphic Designer",
            PLAN: "Plan",
            PRINT: "Printing",
            PRINTER: "Printer",
            PROCESS: "Process",
            RANGE: "Brand",
            REFNUMBER: "Reference Number",
            RELECTURE_JOB: "Proofreading Number",
            RELECTURE_JOB_EXE: "EXE Proofreading Number",
            RELECTURE_JOB_REPRO: "REPRO Version Number",
            SERVICE: "Services",
            STATUS: "Status",
            SUPPORT: "Support",
            TEXTMININ: "Minimum Negative Texts",
            TEXTMINIP: "Minimum Positive Texts",
            TYPEJOB: "Type",
            ZONEDEBORDDEV: "Development Overflow Zone",
            ZONEDEBORDLAIZE: "Width Overflow Zone",
            ZONESECUCOUP: "Safety Cutting Zone",
            ZONESECURAIN: "Safety Creasing Zone",
            SUPPORT_COMM: "Printer Specifics",
            RIVERFLOW: "Riverflow",
            RIVERFLOW_JOBTYPES: "Job Types to Create",
            RIVERFLOW_DATE: "Job Date",
            CREATE_IN_RIVERFLOW: "Create Job in Riverflow",
            RIVERFLOW_THOSE_TYPE_ARE_LOCKED: "Those job types are locked for this date : ",
            COMM_CREA: "Creation Comment",   
            CATEGORY: "Category",
            SUB_CATEGORY: "Sub Category",
        },
        LAST_REVISION: "Last customer control (exe) on",
        LAST_VERSION: "Last customer control (repro) on",
        MASTER: "Parent Project",
        OTHER: "Other",
        OTHER_COMMENT:"Other Comment",
        PREFILL: "Pre-fill fields",
        EASYDIADEM: "EasyDiadem",
        PREFILL_MASTER: "Pré-remplir le projet maître",
        REPRO: {
            CHROMIE: {
                CHROMIE: "Chromie",
                CIBLE: "Provided Color Target",
                ENCR: "Maximum Inking",
                PTMAX: "Max Point",
                PTMIN: "Min Point",
                REPROPROFIL: "Profile",
                TRAM: "Screen"
            },
            FILE1: "Files to Provide",
            FILE2: "PSD",
            OPERATOR: "REPRO Graphic Designer",
            REPRO: "REPRO",
            RETRAIT: {
                BLANC: "White Removal",
                COULEUR: "Color Removal",
                VERNIS: "Varnish Removal"
            },
            TRAP: "Trapping",
            TRAP_RETRAIT: "Trapping and Removal"
        },
        SAVE: "Save Project",
        SEARCH: "Search for a project...",
        TYPE_JOB_REQUIRED: "Required Job Type",
    },
    MASTER: {
        CREATED: "Le projet maitre à été créé",
        ERROR: "Une erreur est survenue",
    },
    PROJECTS: "Projects",
    PROJECTTABLE: {
        PAGE_TITLE: "Project Table"
    },
    FACE: "Front View",
    "THREE-QUARTER-LEFT": "Three-Quarter Left",
    "THREE-QUARTER-RIGHT": "Three-Quarter Right",
    "FACE-INCO": "Legal Front INCO",
    RESTART: "Restart",
    REQUIRED: "Required Field",
    PR_REQUIRED: "EXE: Remember to create a text file (to avoid workflow crashes)",
    RESET_DIALOG: {
        TEXT: "Texts and custom fields will be deleted in all languages",
        TITLE: "Do you want to reset the text file?"
    },
    SAVE: "Save",
    SELECT_JOB_CHILD_REFERENCE: "Select a child project",
    SELECT_JOB_MASTER_REFERENCE: "Select a parent project",
    SELECT_FILTER_FIELDS: "Click here to select text file fields",
    SELECT_REFERENCE: "Select a reference",
    SHOOTING: "Which shots for your Packshot (it will be delivered in jpeg, png formats - Low def and High def):",
    SELECT_SHOOTING: "Please select the shots",
    SOMETHING_WENT_WRONG: "An error occurred",
    SPLITVIEW: {
        PAGE_TITLE: "COMPARE VIEW"
    },
    SUBMIT: "Submit",
    SUCCESS: {
        CREATE: "Your texts have been created",
        FORM: {
            CREATE: "Your text file has been created",
            SAVE: "Your text file has been saved",
            SUBMIT: "Text file submitted!"
        },
        SAVE: "Your texts have been saved",
        SUBMIT: "Texts submitted!"
    },
    SYSTEM: "System",
    TABLE: "Project Table",
    COMPARE: "Compare",
    TEL: "Phone",
    TEXT: {
        COPIED: "Text copied!",
        COPIED_IDENTIFIER: "Identifiant copié !",
        PASTED: "Text pasted from clipboard"
    },
    TEXTOK: {
        SUBMIT: "Texts submitted!",
        TITLE: "Texts OK"
    },
    TEXT_APPRO_IN_PROGRESS: "Approvers processing",
    TEXT_IN_PROGRESS: "Processing",
    TEXT_SEND: "Texts sent",
    TITLE: "TITLE",
    ARE_YOU_SURE: "Are you sure?",
    ARE_YOU_SURE_ARCHIVE: "The project will be archived",
    ARE_YOU_SURE_UNARCHIVE: "The project will be unarchived",
    ARE_YOU_SURE_DELETE_GROUP_TEXT: "The group will be deleted. (Deleted groups will be removed from ongoing projects)",
    ARE_YOU_SURE_DELETE_LANGUAGES: "Texts in this language will be deleted",
    DELETE: "Delete",
    TOOLBAR: {
        IN_PRODUCTION: "Send to production",
        CREATE: "Create text file",
        FIELD: "Add a custom field",
        CUSTOM_COLUMN: "Add a custom column",
        LANGUAGE: "Add a language",
        FORM: "Reset text file",
        PREFILL: "Pre-fill fields",
        SUBMIT: "Submit text file",
        SUBMIT_MODIF: "Submit modifications"
    },
    TRAME: "Screen Ruling",
    TYPE_TRAME: "Type of Screen Ruling",
    TXT_SUBMIT: "Texts submitted",
    TYPE: "Epson File",
    UNSAVED_CHANGES: "Some changes have not been saved. Exit anyway?",
    USERS: "Users",
    GROUPS: "Groups",
    USER: {
        ARE_YOU_SURE_DELETE_USER_TEXT: "The user will be deleted",
        BOTH_USED: "Username and email already in use",
        CREATE: "Create User",
        CREATE_OTHER: "Would you like to create another user?",
        CREATE_USER: "Create a user",
        ADD_USER: "Add a user",
        CUSTOMER: "Customer",
        DELETE: "Delete",
        DELETE_SUCCESS: "User deleted",
        EDIT_ACCOUNT_SUCCESS: "Account updated",
        EDIT_SUCCESS: "User updated",
        ADD_SUCCESS: "User created",
        EDIT_FAILED: "Unable to update user",
        EDIT_USER: "Edit user",
        EMAIL: "Email",
        FIRSTNAME: "First Name",
        MANAGE_USERS: "Manage users",
        GROUP: "Group",
        LASTNAME: "Last Name",
        MAIL_USED: "Email already in use",
        MY_ACCOUNT: "My account",
        PASSWORD: {
            CURRENT: "Current password",
            NEW: "New password",
            NEW_CONFIRM: "Confirm new password",
            NOT_SAME: "Passwords do not match",
            RESET: "Change my password",
            UPDATE_SUCCESS: "Password updated",
            WRONG_CURRENT: "Incorrect current password"
        },
        UPDATE: "Update",
        USER: "User",
        USERNAME: "Username",
        USERNAME_USED: "Username already used",
        IS_DIADEM: "User is part of Diadem",
        IS_DIADEM_SHORT: "Diadem Users",
    },
    USERNAME: "Username",
    SELECT_DOC: "Please select a document",
    GROUP: {
        DELETE_SUCCESS: "Group deleted",
        CREATE: "Create the group",
        CREATE_GROUP: "Add a group",
        CUSTOMER: "Customer",
        CUSTOMERS: "Customers",
        CPP: "CPP",
        MANAGE_GROUPS: "Manage groups",
        BRANDS: "Brands",
        DELETE: "Delete",
        EDIT_GROUP: "Edit group",
        EDIT_SUCCESS: "Group updated",
        CREATE_SUCCESS: "Group created",
        GROUP: "Group",
        UPDATE: "Update",
        NAME: "Name",
        USERS: "Users",
        APPROBATION_BEHAVIOR: "Approval behavior",
        ONE_FOR_ALL: "One for all",
        INDIVIDUAL: "Individual",
        ONE_FOR_ALL_DESCR: "Any member of the group can choose to approve/reject the approval, and following their choice, the status of the whole group is affected accordingly.",
        INDIVIDUAL_DESCR: "Each member of the group must approve/reject the approval before the group's status is calculated accordingly."
    },
    INVALID_FILE: "The file is invalid",
    INVALID_FILE_SIZE: "The file is too large",
    INVALID_FILE_TYPE: "The file type is incorrect",
    VALID: "Validate",
    VALIDATORS: {
        "FORMDIALOG-CPP": "Please select a valid CPP",
        "FORMDIALOG-PRINTERS": "Please select a valid printer",
        "FORMDIALOG-CUSTOMER": "Please select a valid customer",
        "FORMDIALOG-IDENTIFIANT": "Please select a valid identifier",
        "FORMDIALOG-FAMILY": "Please select a valid family",
        "FORMDIALOG-FORM": "Please enter a field name",
        "FORMDIALOG-LANGUAGE": "Please select at least one language",
        "FORMDIALOG-OPERATOR": "Please select a valid operator",
        "FORMDIALOG-PROJECT": "Please select a valid project",
        "FORMDIALOG-RANGE": "Please select a valid brand",
        "FORMDIALOG-CONTACT": "Please select a valid contact",
        "FORMDIALOG-CUSTOMER_INVOICE": "Please select a valid billing customer",
        "NO_WHITESPACE_ALLOWED": "No spaces allowed",

    },
    VALID_EMAIL: "Invalid email",
    INVALID_USERNAME: "Invalid username",
    INVALID_PASSWORD: "Invalid password",
    VALID_TEL: "Invalid phone number",
    VIEW: {
        BUTTON: "View",
        PROJECT_REF: "Reference",
        ID_MAITRE: "Master ID",
        COMPARE: "Compare",
        DOCUMENT_APPROVER_REJECT: "The approver has rejected the document.",
        DOCUMENT_YOU_ACCEPT: "You have approved the document.",
        DOCUMENT_YOU_REJECT: "You have rejected the document.",
        DOCUMENT_APPROVER_ACCEPT: "The approver has approved the document.",
        INTERN_CONTROL_REJECT: "Internal control has been rejected.",
        INTERN_CONTROL_APPROVED: "Internal control has been approved.",
        DOCUMENT_IN_PRODUCTION: "The document is in production.",
        PENDING_DELIVERY: "Pending delivery"
    },
    WARNING: "Warning",
    REPRO_IN_PROGRESS: "REPRO in progress",
    EXE_IN_PROGRESS: "Exe in progress",
    YES: "Yes",
    NO: "No",
    ENTER_VALUE: "Enter a value",
    EDIT: "Edit",
    MUST_BE_IN_LIST: "The item must be from the list",
    SYNCBDD: "Synchronize Logipub customer database",
    TXT_PR: "Text File",
    VALIDATE: "Validate",
    NEXT: "Next",
    PREVIOUS: "Previous",
    REMOVE_LANGUE: "Remove language",
    SEARCH: "Search",
    SELECT_LANGUAGE: "Select one or more languages",
    SERVICE: {
        PROJECT: "Projects",
        VALIDATORS: {
            PROJECT: "Please select a valid project",
            PRINTERS: "Please select a valid printer"
        },
        PRINTERS: "Printers"
    },
    NOTIFICATIONS: {
        LIST: "Notifications",
        CARTOUCHE_GENERATED: "The {{ type }} cartouche for project {{ projectIdentifier }} has been generated!",
        PROOFSCOPE_GENERATED: "{{ identifier }} - The internal review {{ flow }} has been updated!",
        FILE_VALIDED: "{{ identifier }} - The internal review {{ flow }} is complete!",
        SENT_TO_CLIENT: "{{ identifier }} - The internal review has been published to the customer",
        CHECK_CPP: "{{ identifier }} - A job for which you are CPP is ready to be checked.",
        BRIEF_FILES_ARE_READY: "{{ identifier }} - The customer brief files have been copied",
        FILES_ARE_READY: "{{ identifier }} - The files have been copied",
        START_DELIVERY: "{{ identifier }} - The delivery is ready to be checked",
        CONTROL_DELIVERY_VALIDATED: "{{ identifier }} - Delivery check completed, delivery validated",
        CONTROL_DELIVERY_FAILED: "{{ identifier }} - Delivery check completed, delivery rejected",
        FINAL_DELIVERY: "{{ identifier }} - The delivery has been finalized",
        ERROR: {
            UPDATE_DOC: "{{ identifier }} - The production flow failed at {{ flow }}",
            VALID_FILE: "{{ identifier }} - The file validation flow failed at {{ flow }}",
            BRIEF_FILES_ARE_READY: "{{ identifier }} - The files could not be copied",
            FILES_ARE_READY: "{{ identifier }} - The files could not be copied",
            START_DELIVERY: "{{ identifier }} - The delivery failed at {{ flow }}",
            CONTROL_DELIVERY_FAILED: "{{ identifier }} - The delivery check rejection failed at {{ flow }}",
            CONTROL_DELIVERY_VALIDATED: "{{ identifier }} - The delivery check validation failed at {{ flow }}",
            FINAL_DELIVERY: "{{ identifier }} - The delivery finalization failed at {{ flow }}",
            POST_VALIDATION: "{{ identifier }} - The post-validation failed at {{ flow }}"
        }
    },
    JOB_NO_PROOFSCOPE: "This job does not have an associated text file",
    RESET_PROD: {
        BTN: "Reset Production",
        TITLE: "Production Reset",
        TEXT: "This will reset the review to 'Ready'."
    },
    UPLOADING: "Uploading",
    UPLOADING_IN_PROGRESS: "Files are being uploaded.",
    DOWNLOADING: "Downloading",
    DOWNLOADING_IN_PROGRESS: "Files are being downloaded.",
    ARCHIVING: "Archiving",
    ARCHIVING_IN_PROGRESS:  "The files are loading.",
    ACTUAL_PROJECTS: "Current Projects",
    ADD_CHILD_PROJECT: "Add Child Project",
    THUMB: "Thumbnail",
    JOB_NAME: "Job Name",
    EXTERN_STATE: "General Status",
    INTERN_STATE: "Internal Status",
    CREATION_DATE: "Created by",
    ACTIONS: "Actions",
    ED_JOB: "EasyDiadem Project",
    DASHBOARDHISTORY: {
        VALIDATIONS_HISTORY: "Validation History",
        LOOP: {
            INTERN: "Internal Loop",
            EXTERN: "External Loop"
        },
        ORDER: "Order",
        PLEASE_SELECT_A_VERSION: "Please select a version",
        USER: "User",
        DATE_HOUR: "Date / Time",
        STATE: "Status",
        NO_HISTORY: "No history available",
        STEPS: "Steps",
        MEMBERS: "Members",
        CATEGORY: "Category",
        CONTROL: "Control"
    },
    VERSION_SHORTNAME: "ver.",
    NO_VERSION: "No version",
    SPLITVIEWS: "Reviews",
    INTERN_TITLE: "Internal",
    INTERN_TITLE_SHORT: "Int.",
    EXTERN_TITLE: "External",
    EXTERN_TITLE_SHORT: "Ext.",
    COMPARE_SHORT: "Comp.",
    OR: "or",
    RELECTURES: "Reviews",
    SELECT_DOCUMENT_TO_COMPARE: "Select versions to compare",
    DOCUMENT_A: "Document A",
    DOCUMENT_B: "Document B",
    DETAILS: "Details",
    SEE_PROJECT_HISTORY: "View project history",
    LAST_MONTH: "Last month",
    LAST_SIX_MONTHS: "Last six months",
    LAST_THREE_MONTHS: "Last three months",
    LAST_YEAR: "Last year",
    FILTERS: {
        NAME_OR_ID: "Name or ID",
        START_DATE: "Start Date",
        END_DATE: "End Date",
        REINIT: "Reset",
        SEARCH: "Search",
        PERIOD: "Select a period",
    },
    RELECTURE: "Review",
    YOU_DONT_HAVE_THE_PRIVILEGE: "You do not have the privilege",
    EZD_TABLE: {
        TITLE: {
            DATE: "Date",
            CLIENT: "Customer",
            REF_REQUEST: "Request Ref.",
            REF_PROJECT: "Project Ref.",
            SOURCE_PROJECT: "Original Project",
            PLANNED_SUB: "Planned submission",
            NAME: "Request Name",
            TYPE: "Request Type",
            INFO: "Info",
            ACTIONS: "Actions"
        }
    },
    RESET_PASSWORD: "Reset Password",
    ARE_YOU_SURE_RESET_PASSWORD_TEXT: "The password for user {{ username }} will be reset.",
    ARE_YOU_SURE_RESET_PASSWORD_TEXT_2: "They will receive their new password by email.",
    ATTRIBUTES_NAME: {
        TITLE: "User Attributes",
        CPP: "CPP",
        EXE: "Exe",
        REPRO: "Repro",
        FAB: "Fab",
        OPERATOR: "Operator",
        CLIENT: "Customer"
    },
    FORGOT_PASSWORD: "Forgotten password ?",
    USERNAME_OR_EMAIL: "Username or email",
    REQUIRED_USERNAME_OR_EMAIL: "Username or email required",
    ASK_FOR_REINIT: "Request reset",
    ASK_FOR_REINIT_TEXT: "Your request has been received. If the email address associated with the account is valid, you will receive an email with a link to reset your password.",
    SET_NEW_PASSWORD: "Set a new password",
    BACK_TO_LOGIN_PAGE: "Back to login page",
    RESET_PASSWORD_DIALOG: {
        ERROR: {
            TITLE: "An error occurred",
            TEXT: "Please check the information provided, or try again later."
        },
        REQUEST: {
            SUCCESS: {
                TITLE: "Reset request sent",
                TEXT: "If the username or email provided matches an existing account, you will receive an email with a link to reset your password."
            }
        },
        SET: {
            SUCCESS: {
                TITLE: "Password updated",
                TEXT: "You can now log in with your username and new password."
            }
        }
    },
    NEW_USER: "New user",
    INVALID_CLIENT: "Invalid customer, please access this page from the groups table",
    INVALID_MARQUE: "Invalid brand, please access this page from the groups table",
    FINISH_SELECTION: "Finish selection",
    GROUPS_USERS: "Group's users",
    FIND: "Find",
    SELECT_PAGE_OF_GROUPS: "Select a page of groups",
    EX_PRINTER: "Ex. Printer",
    SELECT_PAGE_OF_USERS: "Select a page of users",
    REQUEST: "Request",
    NEW_REQUEST_DISCLAIMER: "I understand that this service will be entirely managed via the <strong>Easy Diadem</strong> platform, without direct contact with your usual contacts at Diadem. For any service request deemed too complex, or <strong>in case of contact made other than through the Easy Diadem platform, Diadem reserves the right to issue an additional quote based on the complexity of the requested work.</strong>",
    LANG_SELECTION: "Language selection",
    NEW_LANG: "New language",
    LANG_TRANSLATED: "Langue/Language",
    FILE_REDELIVERY_DESC: `Redelivery of {{ type }} files for the project {{ projectIdentifier }}`,
    LB: {
        ALL: 'All',
        WAITING: 'Awaiting processing',
        IN_PROGRESS: 'Current request',
        DELIVERED: "Delivered",
        CANCELLED: "Cancelled",
    },
    CPP: "CPP",
    FAB: "Fab",
    OPERATOR: "Operator",
    CLIENT: "Customer",
    USER_MANUAL: "User Manual",
    LANGS: {
        "aa": "Afar",
        "ab": "Abkhaz",
        "ae": "Avestan",
        "af": "Afrikaans",
        "ak": "Akan",
        "am": "Amharic",
        "an": "Aragonese",
        "ar": "Arabic",
        "as": "Assamese",
        "av": "Avaric",
        "ay": "Aymara",
        "az": "Azerbaijani",
        "ba": "Bashkir",
        "be": "Belarusian",
        "bg": "Bulgarian",
        "bh": "Bihari",
        "bi": "Bislama",
        "bm": "Bambara",
        "bn": "Bengali",
        "bo": "Tibetan",
        "br": "Breton",
        "bs": "Bosnian",
        "ca": "Catalan",
        "ce": "Chechen",
        "ch": "Chamorro",
        "co": "Corsican",
        "cr": "Cree",
        "cs": "Czech",
        "cu": "Old Church Slavonic",
        "cv": "Chuvash",
        "cy": "Welsh",
        "da": "Danish",
        "de": "German",
        "dv": "Divehi",
        "dz": "Dzongkha",
        "ee": "Ewe",
        "el": "Modern Greek",
        "en": "English",
        "eo": "Esperanto",
        "es": "Spanish",
        "et": "Estonian",
        "eu": "Basque",
        "fa": "Persian",
        "ff": "Fulah",
        "fi": "Finnish",
        "fj": "Fijian",
        "fo": "Faroese",
        "fr": "French",
        "fy": "Western Frisian",
        "ga": "Irish",
        "gd": "Scottish Gaelic",
        "gl": "Galician",
        "gn": "Guarani",
        "gu": "Gujarati",
        "gv": "Manx",
        "ha": "Hausa",
        "he": "Hebrew",
        "hi": "Hindi",
        "ho": "Hiri Motu",
        "hr": "Croatian",
        "ht": "Haitian Creole",
        "hu": "Hungarian",
        "hy": "Armenian",
        "hz": "Herero",
        "ia": "Interlingua",
        "id": "Indonesian",
        "ie": "Interlingue",
        "ig": "Igbo",
        "ii": "Sichuan Yi",
        "ik": "Inupiaq",
        "io": "Ido",
        "is": "Icelandic",
        "it": "Italian",
        "iu": "Inuktitut",
        "ja": "Japanese",
        "jv": "Javanese",
        "ka": "Georgian",
        "kg": "Kongo",
        "ki": "Kikuyu",
        "kj": "Kuanyama",
        "kk": "Kazakh",
        "kl": "Greenlandic",
        "km": "Khmer",
        "kn": "Kannada",
        "ko": "Korean",
        "kr": "Kanuri",
        "ks": "Kashmiri",
        "ku": "Kurdish",
        "kv": "Komi",
        "kw": "Cornish",
        "ky": "Kirghiz",
        "la": "Latin",
        "lb": "Luxembourgish",
        "lg": "Ganda",
        "li": "Limburgish",
        "ln": "Lingala",
        "lo": "Lao",
        "lt": "Lithuanian",
        "lu": "Luba-Katanga",
        "lv": "Latvian",
        "mg": "Malagasy",
        "mh": "Marshallese",
        "mi": "Maori",
        "mk": "Macedonian",
        "ml": "Malayalam",
        "mn": "Mongolian",
        "mo": "Moldavian",
        "mr": "Marathi",
        "ms": "Malay",
        "mt": "Maltese",
        "my": "Burmese",
        "na": "Nauruan",
        "nb": "Norwegian Bokmål",
        "nd": "North Ndebele",
        "ne": "Nepali",
        "ng": "Ndonga",
        "nl": "Dutch",
        "nn": "Norwegian Nynorsk",
        "no": "Norwegian",
        "nr": "South Ndebele",
        "nv": "Navajo",
        "ny": "Chichewa",
        "oc": "Occitan",
        "oj": "Ojibwa",
        "om": "Oromo",
        "or": "Oriya",
        "os": "Ossetian",
        "pa": "Punjabi",
        "pi": "Pali",
        "pl": "Polish",
        "ps": "Pashto",
        "pt": "Portuguese",
        "qu": "Quechua",
        "rm": "Romansh",
        "rn": "Rundi",
        "ro": "Romanian",
        "ru": "Russian",
        "rw": "Kinyarwanda",
        "sa": "Sanskrit",
        "sc": "Sardinian",
        "sd": "Sindhi",
        "se": "Northern Sami",
        "sg": "Sango",
        "sh": "Serbo-Croatian",
        "si": "Sinhalese",
        "sk": "Slovak",
        "sl": "Slovenian",
        "sm": "Samoan",
        "sn": "Shona",
        "so": "Somali",
        "sq": "Albanian",
        "sr": "Serbian",
        "ss": "Swati",
        "st": "Southern Sotho",
        "su": "Sundanese",
        "sv": "Swedish",
        "sw": "Swahili",
        "ta": "Tamil",
        "te": "Telugu",
        "tg": "Tajik",
        "th": "Thai",
        "ti": "Tigrinya",
        "tk": "Turkmen",
        "tl": "Tagalog",
        "tn": "Tswana",
        "to": "Tongan",
        "tr": "Turkish",
        "ts": "Tsonga",
        "tt": "Tatar",
        "tw": "Twi",
        "ty": "Tahitian",
        "ug": "Uyghur",
        "uk": "Ukrainian",
        "ur": "Urdu",
        "uz": "Uzbek",
        "ve": "Venda",
        "vi": "Vietnamese",
        "vo": "Volapük",
        "wa": "Walloon",
        "wo": "Wolof",
        "xh": "Xhosa",
        "yi": "Yiddish",
        "yo": "Yoruba",
        "za": "Zhuang",
        "zh": "Chinese",
        "zu": "Zulu",
        "mx": "Mexican",
        "fin": "Finnish"
    },
    SHOOTINGSIDE: {
        FACE: "Front",
        THREEQUARTERLEFT: "Three-quarter left",
        THREEQUARTERRIGHT: "Three-quarter right",
        FACEINCO: "Legal face INCO"
    },
    STATS: {
        EXE: "Execution",
        AR: "A/R",
        NBR: "Nbr",
        MOY: "Average",
        INTERN: "Intern",
        EXTERN: "Extern",
        REPRO: "Reprographie",
        VERSION: "Version",
        VALUES: "Values",
        REF_VALUES: "Reference values",
        GLOBAL_STATS: "Global statistics",
        RELECTURE: "Relecture",
        CUSTOMER_PROD: "Customer production",
        NBR_REF: "(number of references)",
        MOY_TIME: "Average time",
        MOY_TIME_R1: "Average time between R0 and R1",
        MOY_TIME_PR: "Average time between PR",
        R_CUSTOMER: "Relecture client",
        KPI: "KPI",
        SELECT_NOT_ENABLED: "Selection not enabled",
        SELECT_ENABLED: "Select",
        DASHBOARD: "Dashboard",
        DATES:'Dates',
        TE0: "Client Brief",
        TE1: "Production Start",
        TE2: "(sending)",
        TEN: "(Client feedback)",
        TENPLUS1: "Ten+1",
        TP0: "Tp0",
        TP1: "Tp1",
        TP2: "Tp2",
        TPN: "Tpn",
        TPNPLUS1: "Tpn+1",
        NB_AR_INTERN: "Nb A/R intern",
        USERS: "Project Collaborators",
        NB_AR_EXTERN: "Nb A/R extern",
        INFOGRAPHISTES: "Infographistes",
        REACT_INTERN_R: "Reactivity Diadem R+",
        REACT_CLIENT: "Reactivity Client",
        NB_RELECTURES_CLIENT: "Number of relectures client",
        PROJECT: "This project",
        MOY_CHILD_JOB_PARENT: "Average child",
        ALL_PROJECTS: "All projects",
        JOB_PARENT: "parent job",
        MOY_R_CUSTOMER: "Average client R time",
        MOY_TIME_R_CUSTOMER: "Average time between customer validation and production",
        MOY_TIME_RN: "Average time between Rn and Rn+1 and production",
        SELECT_CUSTOMER: "Select a customer",
        SELECT_BRAND: "Select a brand",
        CYCLE: 'Review Cycle',
        AVERAGE: 'Average Review',
        TOOLTIP: {
            TE0: "Client brief reception date",
            TE1: "Production start date",
            TE2: "Proofreading validation date by the CPP",
            TEN: "Client feedback date on Rn",
            TENPLUS1: "Submission date of Rn+1 to client",
            TP0: "Date when the exe was validated – or Repro production start date",
            TP1: "Submission date of Rp1",
            TP2: "Proofreading validation date by the CPP",
            TPN: "Client feedback date on Rpn",
            TPNPLUS1: "Submission date of Rpn+1 to client",
            KPI: "Key performance indicator",
            REF_VALUES: "For all projects"
        }
    },
    INFORMATION_TO_COME: "Information to come",
};

// export const en__ = {
//     RELECTURE_EXTERN: "Proofreading",
//     RELECTURE_INTERN: "Internal proofreading",
//     LAST_REVISION: "Last revision",
//     ARCHIVED: "Archived",
//     PR_UPDATED: "Proofreading updated.",
//     RETURN: "Back",
//     LANGUAGES: "Languages",
//     CANCEL_FULLSCREEN: 'Minimize',
//     INFORMATION: "Informations",
//     ASSERT_INFORMATIONS: "Are the information below still accurate?",
//     COMMENTARY: "Comments",
//     COMMENTARY_PLACEHOLDER: "Please indicate the changes to be made here",
//     RELECTURE_MEMBERS: 'Proofreading members',
//     TODO: "To be verified",
//     DONE: "Done",
//     DOWNLOAD: "Download",
//     PDF_CAN_REUPLOAD: "You can reupload a new PDF file",
//     UPLOAD: "Upload",
//     PDF_PLEASE_REUPLOAD: "Please reupload a new PDF file",
//     FORM: {
//         "TEXT-CORRECTION": "Text correction",
//         "PDF-REPOSITORY": "PDF",
//         "FILE-REDELIVERY": "File redelivery",
//         HEADS: {
//             PLAN: "Plan",
//             CUSTOMER_BRIEF: 'Customer Brief',
//             COLOUR: "Color",
//             CREA_FILES: 'Creative files',
//             IMAGES_TO_RETOUCH: 'Images to be retouched',
//             TECHNICAL_PLAN: 'Technical plan',
//             TEXT_SHEET: 'Text file',
//             PRINTING_METHOD: 'Printing method',
//             COLORING: 'Coloring',
//             BRAND_CHART: 'Brand guidelines',
//             DEADLINE: 'Deadline',
//             VALIDATION_CYCLE: 'Validation cycle',
//             CLIENT_CONTACT: 'Customer contact',
//             ARCHIVE_EXISTING_REFERENCE: 'Existing reference archive',
//             SOURCE_PROJECT: 'Source project',
//             PRINTER: 'Printer',
//             SEQ_COLORS: 'Printing sequence',
//             TEXT: "Text file",
//             RELECTURE: 'Proofreading members',
//             FINALIZATION: 'Finalization',
//             EPSON_CONTACT: 'Contact',
//         },
//     },
//     ASSESSMENT: {
//         PENDING: "Pending",
//         APPROVE: "Approved",
//         REJECT: "Rejected",
//         IN_PROGRESS: "Awaiting approval",
//         READONLY: "Readonly",
//         READONLY_PROOFSCOPE: "Review",
//         PAUSED: "Paused cycle"
//     },
//     STATE: 'State',
//     APPROVAL_SENT: 'Received approvals',
//     SEE_ALL: 'See all',
//     ADDRESS: {
//         ADDRESS: "Address",
//         LASTNAME: "Lastname",
//         FIRSTNAME: "Firstname",
//         STREET: "Address (number and street name)",
//         CITY: "City",
//         CP: "Zip code",
//         EMAIL: "E-mail address",
//         TEL: "Phone number"
//     },
//     FEATURE_TO_COME: "Feature(s) to come",
//     RECENT_PROJECTS: "History",
//     LAST_10_ENTRIES: "Last 10 history entries",
//     HI_DESCRIPTION:
//         "What elements would you like to highlight on your Hero Image? (it will be delivered in jpeg and png formats - Low res and High res) : ",
//     CLOSED: "Closed",
//     FULL_SCREEN: "Fullscreen",
//     NO_FULL_SCREEN: "Normal screen",
//     COMPARE_DOC_A: "Document A",
//     COMPARE_DOC_B: "Document B",
//     COMPARE_DOC: "Document",
//     COMPARE_CURRENT: "(current version)",
//     CREATE_PROOFREADING: "Create a proofreading",
//     SELECT_FLOW: "Select a flow type",
//     PROOFREADING_MISSING: "No proofreading found",
//     FLOW: "Flow",
//     EXE: "EXE",
//     REPRO: "REPRO",
//     EZD_EXE: "EZD - EXE",
//     EZD_PDF_REPOSITORY: "EZD - PDF",
//     INFORMATIONS: "Informations",
//     READ_ONLY_APPROVER: "Approver in readonly mode",
//     PRODUCTION_TEXTS: {
//         CREATE_MODIFY: "Edit / Create text file",
//         INSTRUCTIONS: "Write the instructions",
//         UPLOAD: "Text file submission",
//         OPEN: "Open",
//         NAME: "Text file",
//         ADD: "Add a text file",
//         ARE_YOU_SURE_ADD: "Do you want to add a text file ?",
//         ARE_YOU_SURE_REMOVE: "Do you want to remove this text field ?",
//         MANAGE_LANG: "Languages",
//         PREFILL: "Prefill",
//         ADD_FIELD: "Add a field",
//         FIELD_NAME: "Field name",
//         LABEL: "Label",
//         FACING: "Facing",
//         BACK: "Back + sides",
//         FIELD_PLACEHOLDER: "Type the field name",
//         LEGAL: "Legal texts",
//         MARKETING: "Marketing texts",
//         TVN: "Nutritional values",
//         SAVED: "Text file saved",
//         REMOVED: "Text field removed",
//         PREFILL_SELECT_REF: "Please select a reference",
//         PREFILL_SELECT_REF_TEXT: "This action will replace the content of the text file with that of the selected reference. Do you want to continue ?",
//         PREFILL_SELECT_REF_PLACEHOLDER: "PXXXXXX",

//     },
//     TVN: {
//         ADD_FIELD: "Add field",
//     },
//     ERROR_FORM: " errors found in the form. Please correct them before submitting.",
//     COMPARE_VERSION: "Version",
//     COMPARE_RELEC: "Proofreading",
//     COMPARE_RESET: "Reset the comparison",
//     INTERN_REVISIONS: "Internal proofreadings",
//     CLIENT_REVISIONS: "Customer proofreadings",
//     INTERN_REVISIONS_INFO: "Internal",
//     EXTERN_REVISIONS_INFO: "External",
//     CLIENT_REVISIONS_INFO: "Proofreading",
//     VERSION_REVISIONS_INFO: "Versions",
//     NOT_AVAILABLE_YET: "Not available yet",
//     MAIN_APPROVER: "Main approver",
//     INFO_APPROVER: "Informative approver",
//     OBSERVER_APPROVER: "Observer",
//     GROUP_APPROVER: "Group",
//     GROUP_MEMBER: "Group member",
//     SELECT_APPROBATION_TYPE: 'Please select group approval type',
//     MEMBER_ALREADY_ADDED: 'member(s) : ${ name } have been already added.',
//     ONE_FOR_ALL: "Common decision",
//     INDIVIDUAL: "Individual decision",
//     UPLOAD_FILE: "Upload file",
//     MEMBERS_STATUS: {
//         PAUSED: "Paused",
//         PENDING: "No approval",
//         ACCEPT: "Accepted",
//         REJECT: "Rejected",
//         INPROGRESS: "In progress",
//         READONLY: "Readonly",
//         READONLYPROOFSCOPE: 'Review',
//     },
//     REQUEST_STATUS_UPDATE: "Update request status",
//     REQUEST_STATUS_UPDATE_CONFIRMATION: "Would you really update request status ?",
//     REQUEST_STATUS_UPDATED: "Request status has been updated.",
//     ED: {
//         EPSON: {
//             QUANTITY: "Amount of Epson",
//         },
//         ALL: "All",
//         EDIT: 'Edit request.',
//         ALREADY_SENT: "Request already sent.",
//         ARCHIVE: "Specify the relevant archive :",
//         GENERATE_ARCHIVE: "Generate archive",
//         REGENERATE_ARCHIVE: "Regenerate archive",
//         PLEASE_CHOOSE_TEXTS_TYPE: "Please choose how you would like to provide us with the new texts :",
//         ZIP_TXT_DOC: "Please compile all of your request/text file into a single document (PPT/Word/Excel)",
//         ZIP_PLAN_DOC: "Please compile your entire request into a single document (PDF)",
//         INSTRUCTIONS: "You can provide the instructions for the texts here",
//         ARE_YOU_SURE_CLOSE: "The request will be archived.",
//         CLOSE: "Close the request",
//         DASHBOARD: "Dashboard",
//         MEMBERS_INDICATE: "Indicate the members who will review the references",
//         ARE_YOU_SURE_DELETE: "Do you really want to close this request ?",
//         REQUEST_DELETED: "The request has been deleted.",
//         REQUEST_CREATE_ARCHIVE: "Create an archive",
//         REQUEST_CREATE_ARCHIVE_CONFIRMATION: {
//             'HERO-IMAGE': "An archive will be created and sent from the items in the '3. HD' subfolder.\n Please carefully check these items before proceeding.",
//             'VISUALS-ISOLATION': "An archive will be created and sent from the items in the '3. HD' subfolder.\n Please carefully check these items before proceeding.",
//             'PACKSHOT': "An archive will be created and sent from the items in the '3. HD' subfolder.\n Please carefully check these items before proceeding.",
//             "FILE-REDELIVERY": "An archive will be created and sent from the items in the 'z_Elements_divers/_LIVRAISON_[EXE ou REPRO]' subfolder.\n Please carefully check these items before proceeding.",
//         },
//         SHOOTING: "Photo shoots",
//         FINISH: {
//             FINISH: "Finalization",
//             COMM: "You can provide additional information here if you wish :",
//             TXT: "I understand that I take responsibility for the elements that will be approved by me before the delivery of the files.\n Diadem makes the requested corrections, but their validation and the proofreading process are entirely the client's responsibility.",
//             CONTACT_TXT:
//                 "I understand that this service will be entirely managed through the Easy Diadem platform, without direct contact with your usual contacts at Diadem. For any service request deemed too complex, or if contact is made other than through the Easy Diadem platform, Diadem reserves the right to issue an additional quote based on the complexity of the requested work"
//         },
//         FILES: {
//             UPLOAD_SUCCESS: "The files have been successfully uploaded.",
//             UPLOAD_FAILED: "An error occurred during the file upload."
//         },
//         REQUEST: {
//             NAME: 'EZD request',
//             CREATED: "Your request has successfully been created.",
//             SAVED: "Your form has been saved."
//         },
//         STATUS: {
//             INIT: "Creating",
//             PENDING: "Pending",
//             PROJECT_LINKED: "Handled",
//             WAITING_PRODUCTION: "Awaiting production",
//             PRODUCTION: "In production",
//             RELECTURE_IN_PROGRESS: "Proofreading in progress",
//             VALIDATED: "Validated",
//             UNVALIDATED: "Unvalidated",
//             DELIVERING: "In delivery",
//             DELIVERED: "Delivered",
//             CLOSED: "Request closed."
//         },

//         VISUAL_ISOLATION_DESCRIPTION: "Please list the items to isolate (or upload a file)",
//         ACTION: {
//             CLOSE: "Close",
//             DELIVERY: "Deliver",
//         },
//         BRIEFING: {
//             TITLE: "Brief sheet",
//             TYPE_DATAS: "Enter your data",
//             TYPE_DATE: "Select a date using the calendar",
//             MANDATORY: "Required",
//             REQUIRED_FIELD: "Required field",
//             OPTIONAL_FIELD: "Optional field",
//             CUSTOMER_BRIEF: 'Customer brief',
//             COLOUR: "Color",
//             CREA_FILES: 'Creative files',
//             IMAGES_TO_RETOUCH: 'Images to be retouched',
//             ARCHIVE_EXISTING_REFERENCE: 'Existing reference archive',
//             NUM_JOB_QUESTION: 'What is the job number ?',
//             NUM_JOB_LABEL: "Job number",
//             NUM_JOB_LABEL_PLACEHOLDER: "Enter the job number",
//             TECHNICAL_PLAN: 'Technical plan',
//             TECHNICAL_PLAN_CONTENT: {
//                 TYPE: "Request type",
//                 NEW_REF_SHORT: "New reference(s)",
//                 UPDATE_REF_SHORT: "Update of one (or more) reference(s)",
//                 NO_INFO_SHORT: "No information yet",
//                 NEW_REF_TITLE: "Please upload the technical plan file(s) here",
//                 NEW_REF_SUBTITLE: "Note : format PDF (or .ai, .dxf)",
//                 NEW_REF_TITLE_2: "Or let us know if other references have the same plan",
//                 UPDATE_REF_TITLE: "Has the plan (or have the plans) changed compared to the archive ?",
//                 OPTION_YES: "Yes",
//                 OPTION_NO: "No",
//                 UPDATE_REF_SUBTITLE: "Please upload the new technical plan file(s) here",
//                 NO_INFO_TITLE: "No information on this subject yet"
//             },
//             TEXT_SHEET: 'Text file',
//             TEXT_SHEET_CONTENT: {
//                 TITLE: "Where to retrieve the texts ?",
//                 OPTION_ARCHIVE: "Retrieve the texts from the archive (Please fill in the field below)",
//                 OPTION_FILE: "Submission of a text file",
//                 OPTION_ONLINE: "Creation of an online text file",
//                 ARCHIVE_TITLE: "For an archive, please specify the relevant archive(s)",
//                 ARCHIVE_LABEL: "Name or number of the archives",
//                 FILE_TITLE: "For a submission, please upload the text file(s) here",
//                 FILE_SUBTITLE: "Note : Format PDF/Word/Excel/PowerPoint"
//             },
//             PRINTING_METHOD: 'Printing method & printer specifications',
//             PRINTING_METHOD_SHORT: 'Printing method',
//             PRINTING_METHOD_CONTENT: {
//                 PRINTING_METHOD_LABEL: "If you know the printing method(s), please indicate them",
//                 PRINTING_METHOD_SHORT: "Printing method(s)",
//                 PRINTER_NAME_LABEL: "If you know the name of the printer, please indicate it",
//                 PRINTER_NAME_SHORT: "Name of the printer",
//                 SPECIFICATIONS_LABEL: "If you have the printer's specifications, please upload them here"
//             },
//             COLORING: 'Coloring',
//             COLORING_CONTENT: {
//                 TYPE: "What is the color scheme of your reference(s) ?",
//                 NEW_REF_SHORT: "New reference(s)",
//                 UPDATE_REF_SHORT: "Update of one (or more) reference(s)",
//                 NO_INFO_SHORT: "No information yet",
//                 NEW_REF_TITLE: "Please indicate the determined color scheme (or the maximum number of colors allowed for printing)",
//                 UPDATE_REF_TITLE: "Has the color scheme changed compared to the archive ?",
//                 OPTION_YES: "Yes",
//                 OPTION_NO: "No",
//                 UPDATE_REF_SUBTITLE: "Please indicate the determined color scheme (or the maximum number of colors allowed for printing)",
//                 NO_INFO_TITLE: "No information on this subject yet"
//             },
//             BRAND_CHART: 'Brand guidelines',
//             BRAND_CHART_TITLE: "If you have a brand guidelines document, please upload it here",
//             BRAND_CHART_SUBTITLE: "Note: It is not necessary if there isn't one or if Diadem already has one",
//             DEADLINE: 'Deadline',
//             DEADLINE_TITLE: "Please indicate the delivery deadline for the execution files",
//             VALIDATION_CYCLE: 'Validation cycle',
//             VALIDATION_CYCLE_TITLE: "If you are using our LiveCo review platform, please list the people to be included in the validation cycle",
//             CUSTOMER_CONTACT: 'Customer contact',
//             CUSTOMER_CONTACT_TITLE: 'Please let us know who the client contact is',
//             SUBJECT_TITLE: "Customer brief",
//             SUBJECT_LABEL: "What is the purpose of the requested work ?",
//             OTHER_TITLE: "Please specify :",
//             OTHER_LABEL: "Work to be done",
//             OTHER_SUBJECT_PLACEHOLDER: "Please describe the work",
//             BRIEF_TITLE: "Brief preparation",
//             BRIEF_LABEL: "If you have prepared a brief, please upload it here",
//             COMMENT: "Additional information if needed",
//             COMMENT_PLACEHOLDER: "Add a comment",
//             REMARK: "Comments",
//             CREA_FILES_TITLE: "If there are one or more new references, please upload the approved creative source files here",
//             CREA_FILES_SUBTITLE: "Précision : Assemblage des fichiers sources Illustrator non vectorisés avec textes vivants",
//             IMAGES_TO_RETOUCH_TITLE: "S'il y a des nouvelles images à retoucher, veuillez déposer ici les fichiers sources (Les achats d’art ou les shoots)",
//             IMAGES_TO_RETOUCH_SUBTITLE: "Précision : Images Photoshop sources non aplaties, traitées en CMJN.",
//             ARCHIVE_REF_TITLE: "Si vous disposez d'archives pour votre (ou vos) référence(s), veuillez déposer les fichiers existants ici",
//             ARCHIVE_REF_SUBTITLE: "Précision : De préférence les fichiers sources d'Exécution : Illustrator non vectorisé avec textes vivants, PDF, et fichiers Photoshop pour les images. Au minimum un PDF.",
//             SUBJECTS: {
//                 UPDATE: "Mise à jour d'une référence existante",
//                 UPDATE_MULTIPLE: "Mise à jour de plusieurs références existantes",
//                 NEW_REF: "Exécution d'une nouvelle référence",
//                 NEW_MULTIPLE: "Exécution de plusieurs nouvelles références",
//                 LOGO_EXE: "Exécution de logo",
//                 OTHER: "Autres",
//                 EXE_ONLY: "Exécution seule",
//                 EXE_AND_RETOUCH: "Exécution et Retouche d'image",
//                 RETOUCH_ONLY: "Retouche d'image seule",
//                 FILE_PREPARATION: "Préparation de fichiers pour maquettes",
//                 ISOLATION: "Isolations (pictos, logos, visuels...)",
//             },
//             NAME_QUESTION: "Quel est le nom du projet ou de la référence à traiter?",
//         },
//         WHAT_CAN_WE_DO: "Que pouvons-nous faire pour vous aujourd'hui ?",
//         TASKS: {
//             PACKSHOT: "Réalisation d’un packshot",
//             HERO_IMAGE: "Réalisation d’une Hero image",
//             "HERO-IMAGE": "Réalisation d’une Hero image",
//             "NEW-ACCOUNT": "Nouveau compte",
//             BRIEFING: "Fiche de Brief Client",
//             NEW_ACCOUNT: "Nouveau compte",
//             NEW_EPSON: "Nouvelle Epson",
//             "NEW-EPSON": "Nouvelle Epson",
//             VISUALS_ISOLATION: "Isolation de visuel",
//             "VISUALS-ISOLATION": "Isolation de visuel",
//             FILE_REDELIVERY: "Relivraison de fichiers",
//             "FILE-REDELIVERY": "Relivraison de fichiers",
//             TEXT_CORRECTION: "Correction de texte simple",
//             "TEXT-CORRECTION": "Correction de texte simple",
//             "PLAN-CORRECTION": "Correction de plan (sans correction de textes)",
//             PLAN_CORRECTION: "Correction de plan (sans correction de textes)",
//             "TEXT-PLAN-CORRECTION": "Correction de texte et plan",
//             TEXT_PLAN_CORRECTION: "Correction de texte et plan",
//             PDF_REPOSITORY: "Dépôt de PDF",
//             "PDF-REPOSITORY": "Dépôt de PDF",
//             NONE: 'Aucune',
//         },
//         "PDF-REPOSITORY-TITLE": "Dépôt de PDF",
//         "PDF-REPOSITORY": "Dépôt de PDF",
//         WHAT_DO_YOU_WANT: "Merci de nous préciser votre demande *",
//         WHICH_PROJECT: "Sur quel projet voulez-vous isoler un visuel ?",
//         SEARCH_PROJECT: "Rechercher un projet...",
//         SEND_DEMAND: "Envoyer la demande",
//         SAVE_DEMAND: "Sauvegarder les modifications",
//         LINK_PROJECT: "Relier la demande",
//         LINK_USER: "Créer compte",
//         PLAN_UPLOAD: "Dépôt de plan",
//         OTHER_TYPE_REQUEST: "Autre type de demande",
//         NEW_REQUEST: "Création d'une nouvelle demande",
//         EDIT_REQUEST: "Edition de la demande",
//         REQUEST_TYPE: "Type de requête",
//         YOUR_DEMAND: "Votre demande",
//         REQUEST_CREATE_PLACEHOLDER: "Séléctionnez un type de demande pour afficher puis remplir les champs correspondants",
//         HAS_BEEN_TAKEN_INTO_CONSIDERATION: "a bien été prise en compte",
//         AN_ERROR_OCCURED: "Une erreur est survenue",
//         CLICK_HERE_TO_DROP_PDF: "Cliquez ici pour déposer votre PDF",
//         CLICK_HERE_TO_DROP_FILE: "Veuillez cliquer ou déposer votre fichier ici",
//         CLICK_HERE_TO_DROP_FILES: "Veuillez cliquer ou déposer vos fichiers ici",
//         MAX: "Maximum",
//         FILES_MIN: "fichiers",
//         ALLOWED_FORMATS: "formats autorisés",
//         FILE_DOWNLOAD: "Fichier téléchargé",
//         PROJECT_LABEL: "Nommez votre projet :",
//         PROJECT_LABEL_PLACEHOLDER: "Nom du projet",
//         MEMBERS: "Membres",
//         ADD_MEMBER: "Ajouter un membre",
//         STEP: {
//             QUESTION: "Les informations ci-dessous sont-elles toujours exactes ?",
//             QUESTION_PLAN: "En dehors du plan, les informations ci-dessous sont-elles toujours exactes ?",
//             MEMBERS_TXT: "Indiquer les contacts qui reliront la référénces après corrections",
//             COMM: "Quels sont les changements à apporter ?",
//             PROOFREADING: "Modifier/Créer fiche texte",
//             INSTRUCTIONS: "Écrire instructions",
//             UPLOAD: "Dépôt de fiche textes",
//             TXT: "Veuillez choisir comment vous souhaitez nous indiquer les nouveaux textes :",
//             PLAN: "Veuillez choisir comment vous souhaitez nous indiquer le nouveau plan :"
//         },
//         TYPES: {
//             "HERO-IMAGE": "Héro Image",
//             "VISUALS-ISOLATION": "Isolation de visuel",
//             "PDF-REPOSITORY": "Dépôt de PDF",
//             "TEXT-CORRECTION": "Correction de texte",
//             "PLAN-CORRECTION": "Correction de plan",
//             "TEXT-PLAN-CORRECTION": "Correction de texte et plan",
//             "NEW-EPSON": "Nouvelle Epson",
//             PACKSHOT: "Réalisation d’un packshot",
//             "FILE-REDELIVERY": "Relivraison de fichiers",
//             "NEW-ACCOUNT": "Nouveau compte",
//             BRIEFING: "Fiche de Brief Client",
//         },
//         DOWNLOAD: "Télécharger les éléments",
//         USERNAME_EXIST: "Ce nom est déjà utilisé. Veuillez en choisir un autre",
//         EMAIL_EXIST: "Cet email est déjà utilisé. Veuillez en choisir un autre",
//         STEPPER: {
//             INIT: {
//                 TITLE: "Commande en attente d'information",
//                 DESC: "Veuillez saisir les élements manquants"
//             },
//             PENDING: {
//                 TITLE: "Commande prise en compte",
//                 DESC: "Requête easyDiadem reçue"
//             },
//             PROJECT_LINKED: {
//                 TITLE: "En cours de traitement",
//                 DESC: "Requête validée"
//             },
//             WAITING_PRODUCTION: {
//                 TITLE: "Attente de production",
//                 DESC: "Date à définir",
//                 DESC_DATE: "Prévu le %%DATE%%"
//             },
//             PRODUCTION: {
//                 TITLE: "En production",
//                 DESC: "Requête traitée"
//             },
//             RELECTURE_IN_PROGRESS: {
//                 TITLE: "En relecture client",
//                 DESC: ""
//             },
//             DELIVERING: {
//                 TITLE: "Préparation livraison",
//                 DESC: "Préparation des fichiers"
//             },
//             DELIVERED: {
//                 TITLE: "Livré",
//                 DESC: "Fichiers livrés"
//             },
//             CLOSED: {
//                 TITLE: "Traitement terminé",
//                 DESC: "Le projet a été mené a terme"
//             }
//         }
//     },
//     QUANTITY: "Quantité",
//     OLD_REF: "Ancienne référence",
//     NEW_PROJECT: "NOUVEAU PROJET CRÉÉ",
//     PROOFREADING: "Relecture",
//     NEW_PROOFREADING: "Nouvelle relecture",
//     ADD_FIELD: "Champ personnalisé",
//     ADRESS: "Adresse",
//     ALERT: {
//         MODIF_DOC: "Attention, vous ne pourrez plus revenir sur le document"
//     },
//     NO_PROOFREADING: "Pas de relecture",
//     APPROVAL: {
//         APPROVED: "Votre document a été approuvé",
//         REJECT: "Votre document a été rejeté",
//         TITLE: "Approbation"
//     },
//     APPROVE: {
//         REJECT: "Rejeter",
//         VALID: "Approuver"
//     },
//     APPROVERS: {
//         DIALOG: {
//             TEXT: "Attention, vous ne pourrez plus revenir sur le document",
//             TITLE: "Envoyer vos commentaires"
//         }
//     },
//     BACK: "Dos + côtés",
//     CANCEL: "Annuler",
//     CITY: "Ville",
//     CODES: {
//         ADRESS: "Adresse",
//         CODE: "Code",
//         CODES: "Gencod / QR code",
//         COLOR: "Couleur",
//         CORRECT: "Correction",
//         DATAMATRIX: "Datamatrix",
//         NAMEMACHINE: "Nom du cahier des charges",
//         ECOLOR: "Couleur",
//         GENCOD: "Gencod",
//         GENCODTAILLE: "Taille mini",
//         QRCODE: "QR CODE",
//         RLB: "RLB",
//         SENS: "Sens",
//         TAILLE: "Modules / Tailles mini",
//         TYPE: "Type",
//         NAMESUPPORT: "Nom du support"
//     },
//     COLORS: {
//         MANAGE: "Gérer les couleurs",
//         COLOR: "Couleur",
//         COLORS: "Mise en couleurs",
//         COMM: "Spécificités imprimeur",
//         COLORMAX: "Nombre de couleur maximum",
//         MECREEL: "Nombre de couleur utilisées",
//         PRINTCOLOR1: "Couleur 1",
//         PRINTCOLOR10: "Couleur 10",
//         PRINTCOLOR2: "Couleur 2",
//         PRINTCOLOR3: "Couleur 3",
//         PRINTCOLOR4: "Couleur 4",
//         PRINTCOLOR5: "Couleur 5",
//         PRINTCOLOR6: "Couleur 6",
//         PRINTCOLOR7: "Couleur 7",
//         PRINTCOLOR8: "Couleur 8",
//         PRINTCOLOR9: "Couleur 9",
//         PRINTFINITION1: "Finition 1",
//         PRINTFINITION10: "Finition 10",
//         PRINTFINITION2: "Finition 2",
//         PRINTFINITION3: "Finition 3",
//         PRINTFINITION4: "Finition 4",
//         PRINTFINITION5: "Finition 5",
//         PRINTFINITION6: "Finition 6",
//         PRINTFINITION7: "Finition 7",
//         PRINTFINITION8: "Finition 8",
//         PRINTFINITION9: "Finition 9",
//         PRINTFINITIONS: "Finitions",
//         PRINTSEQ: "Séquence impression",
//         REFS: "Références",
//         SAMECOLORS: "Couleur communes",
//         VALIDATORS: {
//             NUMERIC: "Veuillez saisir un nombre"
//         }
//     },
//     COLORBOOKS: {
//         COLORBOOKS: "Nuancier (couleurs)",
//         FINISHCOLORS: "Nuancier (finitions)",
//         VALIDATORS: {
//             COLORS: "Veuillez saisir un nom de couleur",
//             CATEGORIES: "Veuillez sélectionner une catégorie"
//         },
//         CATEGORIES: "Catégories de couleur",
//         COLORS: "Couleurs à ajouter",
//         PLACEHOLDER: "PANTONE 100 C, PANTONE 101 C, PANTONE 102 C, PANTONE 103 C, PANTONE 104 C, PANTONE 105 C, PANTONE 106 C...",
//         UPDATE: "La palette de couleurs a été mis à jour",
//         CREATE: "La palette de couleurs a été créée",
//         DIALOG: {
//             EXIST: "Les couleurs suivantes existent déjà dans cette catégorie (elles ne seront pas créées)"
//         }
//     },
//     COMM: "Commentaires",
//     COMM_SUBMIT: "Commentaires sauvegardés",
//     CONSTRAINTS: "Contraintes techniques",
//     CONTACT: "Contact",
//     CREATE: "Créer",
//     CREATE_CHILD: "+ projet enfant",
//     CREATE_DIALOG: {
//         TEXT: "Envoyer au client ?",
//         TITLE: "Fiche texte"
//     },
//     CREATE_MASTER: "+ projet parent",
//     CUSTOM_FIELDS: "Champs personnalisés",
//     CUSTOMER: {
//         SEARCH: "Rechercher un client..."
//     },
//     MEMBERS: "Membres",
//     CONTROL: "Contrôle",
//     DASHBOARD: {
//         BRIEF: "Fiche de brief",
//         SPLITVIEW: "Relecture client",
//         SPLITVIEW_INTERN: "Relecture interne",
//         FOLDER: "Ouvrir le dossier",
//         PACKZ: "Ouvrir avec PacKZ",
//         UPDATE_JOB: "Editer le projet",
//         HISTORY: "Historique des relectures",
//         CONTROL_INTERN: "Relecture interne",
//         COMPARE: "Comparer des versions",
//         PREVIEW: "Prévisualiser",
//         SET_READY: 'Le job est prêt',
//         REFRESH: 'Rafraîchir la vue',
//         FULLSCREEN: 'Plein écran',
//     },
//     STATUS: "Statut",
//     DESCRIPTION: "DESCRIPTION",
//     DIALOG: {
//         APPROVE: {
//             REJECT: "Rejeter",
//             TEXT: "",
//             TITLE: "Approbation du document",
//             VALID: "Approuver"
//         },
//         ASSESSMENT: {
//             TEXT: "",
//             TITLE: "Approbation du document",
//             VALID: "Approuver",
//             APPROBATION: "Approbation",
//             REJECT: "Rejet",
//             APPROBATION_TXT: "Confirmez-vous l’approbation du document?",
//             REJECT_TXT: "Confirmez-vous le rejet du document?"
//         },
//         PAUSE: {
//             TITLE: "Pause",
//             TEXT: "Mettre en pause la relecture en cours"
//         },
//         RESTART: {
//             TITLE: "Redémarrer le cycle",
//             TEXT: "Redémarrer la relecture en cours"
//         }
//     },
//     DOCUMENT_READONLY: "Lecture seule",
//     DOCUMENT_READONLY_PROOFSCOPE: "Consultation",
//     READONLYPROOFSCOPE: "Consultation",
//     READONLY: "Lecture seule",
//     PENDING: "En attente",
//     DOCUMENT_PENDING: "En attente",
//     DOCUMENT_APPROVE: "Document approuvé",
//     ACCEPT: "Document approuvé",
//     DOCUMENT_REJECT: "Document rejeté",
//     REJECT: "Document rejeté",
//     "INPROGRESS-R": "En cours de traitement",
//     INPROGRESS: "En cours de traitement",
//     EMAIL: "Email",
//     ERROR: {
//         NODATA: "Impossible de récupérer les données",
//         NOREVISIONS: "Pas de relecture pour ce projet",
//         NOSUBMIT: "Impossible de soumettre les données",
//         NO_EDIT_MASTER: "Impossible de modifier les données d'un projet parent",
//         ONLYCPPCAN: "Seuls les CPP sont autorisés à créer et éditer des Projets",
//         MUST_BE_IN_LIST: "L'entrée selectionnée doit être dans la liste"
//     },
//     NEW_PLAN: "Nouveau plan",
//     FACING: "Facing",
//     FIELD_NAME: "Nom du champ",
//     FILETMINNEG: "Filet minimum négatif",
//     FILETMINPOS: "Filet minimum positif",
//     GO_TO_HOME: "Menu",
//     ERRORS: {
//         QUANTITY: "Veuillez saisir une quantité valide 0 - 5",
//         REQUIRED_EMAIL: "Veuillez saisir une adresse email valide",
//         TEL: "Veuillez saisir un numéro de téléphone valide",
//     },
//     HOME: {
//         SPLIT_VIEW: "Relecture",
//         SPLIT_VIEW_INTERN: "Relecture interne",
//         ARCHIVE: "Archiver",
//         ARCHIVED: "Archivé",
//         COLORBOOKS: "Nuancier",
//         DISARCHIVE: "Désarchiver",
//         OPEN_FOLDER: "Ouvrir le dossier",
//         BRIEF: "Fiche de brief",
//         CREATE: "Fiche textes",
//         CREATE_CHILD: "Projet enfant",
//         CREATE_MASTER: "Projet parent",
//         CREATE_PRINTER: "Créer un imprimeur",
//         CREATE_PROJECT: "Créer un projet",
//         CREATE_USER: "Ajouter un utilisateur",
//         EDIT_PROJECT: "Éditer un projet",
//         FORM: "Fiche textes",
//         KIOSK: "Cloudflow",
//         MENU: "Menu",
//         PRINTER: "Imprimeur",
//         HOME: "Accueil",
//         PROJECT: "Projet",
//         SPLITVIEW: "Relecture client",
//         TABLE_PROJECT: "Projets",
//         TABLE_PRINTER: "Imprimeurs",
//         TABLE_USER: "Utilisateurs",
//         UPDATE: "Éditer une fiche textes",
//         USERS: "Utilisateurs",
//         ACCOUNT: "Mon compte"
//     },
//     INFO: "Informations générales",
//     MINTXTNEG: "Texte minimum négatif",
//     MINTXTPOS: "Texte minimum positif",
//     NAME: "Nom",
//     NOPERMISSION: {
//         TEXTAPPRO: "Textes envoyés aux approbateurs",
//         TEXTOK: "Textes sont déjà OK",
//         TITLE: "Pas de permission",
//         TITLE_TEXTOK: "Pas de permission"
//     },
//     NOREF: "Pas de référence disponible",
//     NORESULT: "Aucun résultat",
//     NUTRITABLE: {
//         AR_PORTION: "% AR / portion",
//         FOR_100: "100",
//         FOR_100_UNIT: "g/ml",
//         FOR_PORTION: "Une portion de",
//         UNIT_G: "(g)",
//         UNIT_KCAL: "(Kcal)",
//         UNIT_KJ: "(kJ)",
//         VALEUR_MOYENNES: "Valeurs moyennes pour :"
//     },
//     OK: "Ok",
//     ORIENTATION: "Orientation",
//     PAYS: "Pays",
//     PC_EDIT_SUCESS: "Vos modifications ont été enregistrées",
//     POSTALCODE: "Code postal",
//     PREFILL_JOB_CHILD_REFERENCE: "Pré-remplir les champs du projet enfant",
//     UPDATE_DATE: "Date de Maj",
//     PAUSE: "Pause",
//     PASSWORD: "Mot de passe",
//     CONNECTION: "Connexion",
//     PRINTER: {
//         ADD_CONTACT: "Ajouter un contact",
//         PREFILL_PRINTER: "Pré-remplir les champs imprimeur",
//         SELECT_PRINTER: "Sélectionner un imprimeur",
//         ARE_YOU_SURE_DELETE_PRINTER_TEXT: "L'imprimeur sera supprimé",
//         NEW_PRINTER: "Nouvel imprimeur",
//         CREATE_PRINTER: "Créer un nouvel imprimeur",
//         COLLAGE: "Zone Collage",
//         COLORDEBDEV: "Débord Couleur - développement",
//         COLORDEBLAIZE: "Débord Couleur - laize",
//         CREATE: "Créer imprimeur",
//         EDIT_SUCESS: "Vos modifications ont été enregistrées",
//         FILESEND: "Livraison Fichiers",
//         MACHINE: "Machine",
//         PRINT: "Impression",
//         PRINTER: "Imprimeur",
//         PRINTERS: "Imprimeurs",
//         SENSIMPRESSION: "Sens d'impression",
//         PROCESS: "Procédé d'impression",
//         REPRO: {
//             CONSTRAINTS: "Contraintes",
//             CHROMIE: {
//                 CHROMIE: "Chromie",
//                 CIBLE: "Cible couleur fournie",
//                 ENCR: "Encrage maximum",
//                 PTMAX: "Point maxi",
//                 PTMIN: "Point mini",
//                 REPROPROFIL: "Profil",
//                 TRAM: "Trame",
//                 STEPREUVAGE: "Standard épreuvage"
//             },
//             EPREUVAGE: "Épreuvage",
//             FILE1: "Fichiers à fournir",
//             FILE2: "PSD",
//             OPERATOR: "Infographiste REPRO",
//             REPRO: "REPRO",
//             RETRAIT: {
//                 BLANC: "Retrait Blanc",
//                 COULEUR: "Retrait Couleur",
//                 VERNIS: "Retrait Vernis"
//             },
//             TRAP: "Trapping",
//             TRAP_RETRAIT: "Trapping et retrait"
//         },
//         SECUCOUPE: "Sécurité Coupe",
//         SECURAIN: "Sécurité Raineur",
//         SEND: "Livraison Fichiers",
//         SPECIFICATION: "Emplacement cahier des charges",
//         SPOT: "Lecture Spot ",
//         SUPPORT: "Support"
//     },
//     FINISHBOOKS: {
//         COLORS: "Veuillez saisir un nom de finition",
//         PLACEHOLDER: "VERNIS, VERNIS MAT, VERNIS BRILLANT..."
//     },
//     HISTORY: "Historique",
//     INTERN_HISTORY: {
//         NONE: "Création",
//         "CHECK-AUTO-CONTROL": "Auto-contrôle",
//         READY: "Prêt",
//         "CHECK-QUALITY": "Contrôle qualité",
//         "CHECK-REPRO": "Contrôle repro",
//         "CHECK-PROD": "Contrôle graphique",
//         'PROD-INTERN-REJECTED': "Auto contrôle rejeté",
//         'CPP-INTERN-REJECTED': "Contrôle CPP rejeté",
//         "CHECK-CPP": "Contrôle CPP",
//         "WAITING-AUTO-CONTROL": "Modifications (auto-contrôle)",
//         "WAITING-CHECK-CPP": "Modifications (Ctrl CPP)",
//         "INTERN-FINISHED": "Contrôle CPP validé",
//         CONTROL_APPROVED: "Contrôle validé",
//         CONTROL_REJECTED: "Contrôle non validé",
//         WIP: "En production",
//         'CONTROL-VALIDATED': "Contrôle validé",
//         'CONTROL-FAILED': "Contrôle refusé",
//         GENERATING: "Génération",
//         "PR-CLIENT-INPROGRESS": "Envoyé au client"
//     },
//     EXTERN: {
//         'SEND-PR-CLIENT': "Vérification des textes par le client",
//         'SEND-PR-CPP': "Vérification des textes par le chef de projet",
//         'IN-PROGRESS': "En cours d'approbation",
//         REJECTED: "Document rejeté par le chef de projet",
//         APPROVED: "Document approuvé par le chef de projet",
//         'FEEDBACK-INFO': "Document revu par les approbateurs info.",
//         'FEEDBACK-CLIENT': "Document revu par l'approbateur document.",
//         PAUSED: "Relecture mise en pause.",
//         DELIVERED: "Livré",
//         "POST-VALIDATION": "Post-Validation",
//         INIT: "Attente lancement",
//         'IN-PRODUCTION': "En production",
//         RESTART: "Redémarrer",
//         BTN: {
//             'IN-PRODUCTION': "Envoyer en production",
//             'SEND-PR-CLIENT': "Envoyer au client",
//             'SEND-PR-CPP': "Envoyer au chef de projet",
//             'IN-PROGRESS': "En cours d'approbation",
//             REJECTED: "Rejeter",
//             APPROVED: "Approuver",
//             'FEEDBACK-INFO': "Document revu par les approbateurs info.",
//             'FEEDBACK-CLIENT': "Document revu par l'approbateur document.",
//             PAUSED: "Pause",
//             RESTART: "Redémarrer",
//             DELIVERED: "Livraison",
//             "POST-VALIDATION": "Post-Validation",
//             INIT: "Attente lancement",
//         },
//         DASHBOARD: {
//             'NULL': "Pas de relecture",
//             'SEND-PR-CLIENT': "Relec. txt. - Client",
//             'SEND-PR-CPP': "Relec. txt. - CPP",
//             INIT: "Att. lancement",
//             'IN-PROGRESS': "Att. approbation",
//             REJECTED: "Rejeté",
//             APPROVED: "Accepté",
//             PAUSED: "En pause",
//             'FEEDBACK-INFO': "Appro. info",
//             'FEEDBACK-CLIENT': "Appro. client",
//             'DELIVERED': 'Livré',
//             "POST-VALIDATION": "Post-Validation",
//             'IN-PRODUCTION': "En production",
//         },
//         DIALOG: {
//             'IN-PRODUCTION': "Voulez-vous envoyer la relecture en production ?",
//             'SEND-PR-CLIENT': "Envoyer la relecture au client ?",
//             'SEND-PR-CPP': "Valider la relecture aupres du chef de projet",
//             'IN-PROGRESS': "En cours d'approbation",
//             REJECTED: "Voulez-vous rejeter le document ?",
//             APPROVED: "Voulez-vous approver le document ?",
//             'FEEDBACK-INFO': "Voulez-vous soumettre votre approbation ?",
//             'FEEDBACK-CLIENT': "Voulez-vous soumettre votre approbation ?",
//             PAUSED: "Mettre la relecture en pause ?",
//             DELIVERED: "Voulez-vous livrer le job ?",
//             "POST-VALIDATION": "Voulez-vous remettre le job en production ?",
//             RESTART: "Voulez-vous redémarrer l'approbation ?",
//         }

//     },
//     INTERN: {
//         SELECT_FLOW: {
//             TITLE: "Selection du Flux",
//             DESC: "Veuillez selectionner le flux de travail",
//         },
//         WARN_PROD: {
//             TITLE: "Avertissement !",
//             RELECTURE: "Vous êtes en relecture : ",
//             DESC: "Réalisez votre pack et remplissez les informations dans l'onglet cartouche de votre job dans Liveco 4.0",
//         },
//         TODO: {
//             EXE: "EXE",
//             REPRO: "REPRO",
//             EZD_EXE: "EZD - EXE",
//             INFO_CARTOUCHE: "Infos Cartouche",
//             REPRO_FILES: "Fichier REPRO",
//             EXE_FILES: "Fichier Editable",
//             IMAGE: "Images",
//             PSD: "PSD",
//             VECT_PDF: "Vectoriel PDF",
//             PDF_BD: "PDF BD",
//             VECT_ILLUSTRATOR: "Vectoriel Illustrator",
//             DAM_DELIVERY: "Livraison DAM"

//         },
//         WARN_DELIVERY: {
//             TITLE: "Controle avant livraison !",
//             RELECTURE: "Vous êtes en relecture : ",
//             DESC: "Contrôlez la présence de tous les fichiers nécessaires à la livraison avant Zip. Faire un assemblage via Illustrator si nécessaire à cette étape.",
//         },
//         UNABLE_TO_SUBMIT: "Impossible de soumettre la demande",
//         UNABLE_TO_SUBMIT_TEXT: "S'il s'agit de la relecture EXE, vérifiez que vous avez bien créé la fiche texte, et que celle-ci a également bien été envoyée.",
//         UPDATE_DOC: "Auto-contrôle",
//         NONE: "Brief en cours",
//         READY: "Prêt",
//         "CHECK-AUTO-CONTROL": "Auto-contrôle",
//         "CHECK-QUALITY": "Contrôle qualité",
//         "CHECK-REPRO": "Contrôle REPRO",
//         "CHECK-PROD": "Contrôle graphique",
//         "CHECK-CPP": "Contrôle CPP",
//         GENERATING: "Génération en cours",
//         "PROD-INTERN-REJECTED": "Auto contrôle rejeté",
//         "CPP-INTERN-REJECTED": "Contrôle CPP rejeté",
//         WIP: "En production",
//         'CONTROL-VALIDATED': "Contrôle validé",
//         'CONTROL-FAILED': "Contrôle refusé",
//         CONTROL_APPROVED: "Contrôle validé",
//         CONTROL_REJECTED: "Contrôle non validé",
//         CONTROL_PENDING: "En attente de proofscope",
//         "WAITING-AUTO-CONTROL": "Document en attente de modifications (auto-contrôle)",
//         "WAITING-CHECK-CPP": "Document en attente de modifications (Ctrl CPP)",
//         "INTERN-FINISHED": "Contrôle CPP validé",
//         "RE-DELIVER": "Relivraison",
//         PR_CLIENT_INPROGRESS: "Envoi client",
//         "PR-CLIENT-INPROGRESS": "Envoyé au client",
//         "TO-DELIVER": "A livrer",
//         "CONTROL-DELIVERY-VALIDATED": "Livraison validée",
//         "CONTROL-DELIVERY-FAILED": "Livraison refusée",
//         DELIVERED: "Livraison finalisée",
//         "SWITCH-TO-REPRO": "Passer en REPRO",
//         BTN: {
//             NEXT: {
//                 NONE: "Initialisée",
//                 READY: "Prêt",
//                 "CHECK-AUTO-CONTROL": "Auto-contrôle",
//                 "CHECK-QUALITY": "Ctrl qualité",
//                 "CHECK-REPRO": "Ctrl repro",
//                 "CHECK-PROD": "Ctrl graphique",
//                 "CHECK-CPP": "Ctrl CPP",
//                 RETURN_TO: "Retour à",
//                 CONTROL_APPROVED: "Contrôle validé",
//                 CONTROL_REJECTED: "Contrôle non validé",
//                 CONTROL_PENDING: "En attente de proofscope",
//                 "WAITING-AUTO-CONTROL": "Retour en production",
//                 "WAITING-CHECK-CPP": "Retour en production",
//                 "INTERN-FINISHED": "Contrôle CPP validé",
//                 PR_CLIENT_INPROGRESS: "Envoi client",
//                 "PR-CLIENT-INPROGRESS": "Envoyer au client",
//                 "PROD-INTERN-REJECTED": "Auto ctrl rejeté",
//                 "CPP-INTERN-REJECTED": "Ctrl CPP rejeté",
//                 'CONTROL-VALIDATED': "Contrôle validé",
//                 'CONTROL-FAILED': "Contrôle refusé",
//                 WIP: "En production",
//                 "TO-DELIVER": "A livrer",
//                 "CONTROL-DELIVERY-VALIDATED": "Valider la livraison",
//                 "CONTROL-DELIVERY-FAILED": "Refuser la livraison",
//                 DELIVERED: "Finaliser la livraison",
//                 "RE-DELIVER": "Relivraison",
//                 "SWITCH-TO-REPRO": "Passer en REPRO",
//             },
//             PREVIOUS: {
//                 NONE: "Initialiser",
//                 READY: "Réinitialiser",
//                 "CHECK-AUTO-CONTROL": "Auto-contrôle",
//                 "CHECK-QUALITY": "Ctrl qualité",
//                 "CHECK-REPRO": "Ctrl repro",
//                 "CHECK-PROD": "Ctrl graphique",
//                 "CHECK-CPP": "Ctrl CPP",
//                 RETURN_TO: "Retour à",
//                 CONTROL_APPROVED: "Contrôle validé",
//                 CONTROL_REJECTED: "Contrôle non validé",
//                 CONTROL_PENDING: "En attente de proofscope",
//                 "WAITING-AUTO-CONTROL": "Retour en production",
//                 "WAITING-CHECK-CPP": "Retour en production",
//                 "INTERN-FINISHED": "Contrôle CPP validé",
//                 PR_CLIENT_INPROGRESS: "Envoi client",
//                 "PR-CLIENT-INPROGRESS": "Envoyé au client",
//                 "PROD-INTERN-REJECTED": "Auto ctrl rejeté",
//                 "CPP-INTERN-REJECTED": "Ctrl CPP rejeté",
//                 'CONTROL-VALIDATED': "Contrôle validé",
//                 'CONTROL-FAILED': "Contrôle refusé",
//                 WIP: "Retour en production",
//                 "TO-DELIVER": "Réinitialiser la livraison",
//                 "RE-DELIVER": "Relivraison",
//                 "CONTROL-DELIVERY-VALIDATED": "Retour en livraison validée",
//                 "CONTROL-DELIVERY-FAILED": "Retour en livraison refusée",
//                 DELIVERED: "Finaliser la livraison",
//                 "SWITCH-TO-REPRO": "Passer en REPRO",
//             }
//         },
//         DASHBOARD: {
//             NULL: "Pas de relecture",
//             NONE: "Brief en cours",
//             UPDATE_DOC: "Doc modifié",
//             READY: "Prêt",
//             "CHECK-AUTO-CONTROL": "Auto-contrôle",
//             APPROVED: "Approuvé",
//             REJECTED: "Rejeté",
//             "CHECK-QUALITY": "Ctrl qualité",
//             "CHECK-REPRO": "Ctrl repro",
//             "CHECK-PROD": "Ctrl graphique",
//             "CHECK-CPP": "Ctrl CPP",
//             WIP: "En production",
//             CONTROL_APPROVED: "Ctrl validé",
//             CONTROL_REJECTED: "Ctrl non validé",
//             CONTROL_PENDING: "ATT proofscope",
//             "WAITING-AUTO-CONTROL": "Modifs auto-ctrl",
//             "WAITING-CHECK-CPP": "Modifs Ctrl CPP",
//             "INTERN-FINISHED": "Contrôle CPP validé",
//             "PROD-INTERN-REJECTED": "Auto ctrl rejeté",
//             "CPP-INTERN-REJECTED": "Ctrl CPP rejeté",
//             PR_CLIENT_INPROGRESS: "Envoi client",
//             "PR-CLIENT-INPROGRESS": "Envoyé au client",
//             GENERATING: "Génération en cours",
//             RELECTURE_IN_PROGRESS: "Relecture client en cours",
//             "TO-DELIVER": "A livrer",
//             "CONTROL-DELIVERY-VALIDATED": "Livraison validée",
//             "CONTROL-DELIVERY-FAILED": "Livraison refusée",
//             DELIVERED: "Livraison finalisée",
//             "RE-DELIVER": "Relivré",
//             "SWITCH-TO-REPRO": "Passer en REPRO",
//         },
//         APPROVE: {
//             NONE: "Brief en cours",
//             READY: "Document prêt pour la production.",
//             CONTROL_TO_CPP: "À valider par le CPP",
//             CONTROL_TO_QUALITY: "À valider par le ctrl qualité",
//             CPP: "Ctrl validé",
//             "INTERN-FINISHED": "Voulez-vous valider le contrôle CPP ?",
//             "PR-CLIENT-INPROGRESS": "Envoyer la relecture au client",
//             "CHECK-AUTO-CONTROL": "Document modifié, à valider en auto-contrôle",
//             "CHECK-QUALITY": "Document ok, à valider par le Ctrl qualité",
//             "CHECK-REPRO": "Document ok, à valider par le Ctrl repro",
//             "CHECK-PROD": "Document ok, à valider par le Ctrl graphique",
//             "CHECK-CPP": "Document ok, à valider par le CPP",
//             "PROD-INTERN-REJECTED": "Rejeter l'auto-contrôle",
//             "CPP-INTERN-REJECTED": "Rejeter le contrôle du CPP",
//             WIP: "Passer le job en production",
//             "CONTROL-FAILED": "Document contrôlé, déclaré non valide.",
//             "CONTROL-VALIDATED": "Document contrôlé, déclaré valide.",
//             "TO-DELIVER": "Initialiser la livraison",
//             "CONTROL-DELIVERY-VALIDATED": "Retour en livraison valide",
//             "CONTROL-DELIVERY-FAILED": "La livraison est non-conforme",
//             DELIVERED: "Contrôlez pour voir si tout s'est bien passé ! Si oui, poursuivez, sinon réinitialisez la livraison.",
//             "RE-DELIVER": "Voulez-vous relivrer le job ?",
//             "SWITCH-TO-REPRO": "Voulez-vous passer en REPRO ?",
//         },
//         REJECT: {
//             QUALITY: "Ctrl qualité non validé",
//             GRAPHIC: "Ctrl graphique non validé",
//             OPERATOR: "Auto-contrôle non validé",
//             REJECT: "Ctrl non validé",
//             DOCUMENT: "Document à modifier",
//             "CHECK-CPP": "Document à valider par le CPP"
//         }
//     },
//     LINK_MASTER_REFERENCE: "Liaison entre projet parent et enfant",
//     MAIL: "Email",
//     TABLE_PROJECT: "Mes projets",
//     TABLE_REQUESTS: "Mes demandes",
//     MENU: {
//         USERS: "Utilisateurs",
//         GROUPS: "Groupes",
//         MY_ACCOUNT: "Mon compte",
//         PROJECTS: "Projets",
//         LOG_OUT: "Déconnexion",
//         COLORS: "Nuanciers",
//         COLORBOOKS: "Nuancier (couleurs)",
//         FINISHBOOKS: "Nuancier (finitions)",
//         OPEN_FOLDER: "Ouvrir le dossier",
//         BRIEF: "Fiche de brief",
//         CREATE: "Créer une fiche textes",
//         CREATE_CHILD: "Créer un projet enfant",
//         CREATE_MASTER: "Créer un projet parent",
//         CREATE_PRINTER: "Créer un imprimeur",
//         CREATE_PROJECT: "Créer un projet",
//         CREATE_REQUEST: "Créer une demande",
//         CREATE_USER: "Créer un utilisateur",
//         EDIT_PROJECT: "Éditer un projet enfant",
//         FORM: "Fiches textes",
//         KIOSK: "Cloudflow",
//         MENU: "Menu",
//         EASYDIADEM: "EasyDiadem",
//         PRINTER: "Imprimeurs",
//         HOME: "Accueil",
//         PROJECT: "Projet",
//         SPLITVIEW: "Relecture client",
//         TABLE_PROJECT: "Mes projets",
//         TABLE_PRINTER: "Imprimeurs",
//         TABLE_USER: "Utilisateurs",
//         UPDATE: "Éditer une fiche textes",
//         ACCOUNT: "Mon compte",
//         ED: {
//             ED_REQUESTS: "Mes demandes",
//             NEW_REQUEST: "Nouvelle demande"
//         }
//     },
//     PREVIEW_CARTOUCHE: {
//         NUM_JOB: "Numéro de job",
//         RANGE: "Marque",
//         FOLDER: "Dossier",
//         DATE: "Date",
//         REPRO_OPERATOR: "Infographiste REPRO",
//         EXE_OPERATOR: "Infographiste EXE",
//         IMAGE: "Imports images",
//         GENCOD: "GENCOD",
//         PRINTER: "Imprimeur",
//         PRINT: "Impression",
//         PLAN: "Plan",
//         SUPPORT: "Support",
//         PROFIL: "PROFIL"

//     },
//     PROJECT: {
//         ALL_CUSTOMER: "Tous les clients",
//         MEMBERS: {
//             FILTER_PLACEHOLDER: "Rechercher un utilisateur...",
//             TABLE: {
//                 NAME: "NOM",
//                 PROOFSCOPE: "PROOFSCOPE",
//                 TEXTS: "TEXTES",
//                 APPROVERS: "APPROBATION",
//                 VERSIONS: "VERSION/AVIS",
//                 EMAIL: "E-MAIL",
//                 READ: "Lecture",
//                 WRITE: "Ecriture",
//                 INFO: "Info",
//                 DOCUMENT: "Document",
//                 ERROR: {
//                     APPRODOC: "• Aucun membre n'est désigné approbateur du document",
//                     ATLEASTONEINFO: "• Au moins un approbateur pour information requis",
//                     MAXIMUMONE: "• Il ne peut y avoir qu'un unique approbateur du document"
//                 }
//             }
//         },
//         CART: {
//             CART: "Cartouche",
//             FILETMINNEG: "Filet minimum négatif",
//             FILETMINPOS: "Filet minimum positif",
//             HAUTCHIFFREPOIDS: "Hauteur des chiffres poids/volumes.",
//             HAUTEMETRO: "Hauteur E Métrologique",
//             HAUTXMIN: "Hauteur de x minuscule",
//             MINTXTNEG: "Texte minimum négatif",
//             MINTXTPOS: "Texte minimum positif",
//             POLICEUSED: "Police utilisé",
//             PREFILL: "Pré-remplir cartouche",
//             SHOW_PREVIEW: "Regénérer la prévisualisation",
//             SHOW_PREVIEW_EXE: "Cartouche EXE",
//             SHOW_PREVIEW_REPRO: "Cartouche REPRO",
//             GENERATE_PREVIEW: "Générer le cartouche",
//             GENERATING_FILE: "Génération du fichier",
//             ERROR_GENERATING_FILE: "Erreur lors de la génération du fichier"
//         },
//         CHILD: "Projet enfant",
//         COMM: {
//             CHROM: "Commentaire Chromie",
//             COMM: "Commentaires",
//             CPP: "Commentaire CPP",
//             GAMME: "Commentaire Gamme",
//             HD: "Commentaire HD",
//             IMP: "Commentaire Imprimeur",
//             PREPA: "Commentaire Preparation"
//         },
//         CREA: {
//             COMM: "Commentaire",
//             CREA: "Créa",
//             INFO: "Informations"
//         },
//         CREATE: "Créer projet",
//         CREATED_AT: "créé le",
//         EXE: {
//             BOOKPACK: "Bookpack",
//             CHARTE: "Charte Client",
//             COMM_CPP: "Commentaire CPP",
//             BRIEF: "Brief Exe",
//             DEF: "Définition",
//             EXE: "Exe",
//             IMAGE: "Image",
//             LANGUES: "Ordre des langues",
//             LOGO: "Logo à executer",
//             NB_LANGUES: "Nombre de langues",
//             OPERATOR: "Infographiste Exe",
//             SOFT: "Logiciels",
//             TEXTS: {
//                 ARCHIVE: "Archive",
//                 BRIEF: "Brief Client/Team",
//                 CLIENT: "Fiche Texte Client",
//                 CREA: "Créa",
//                 TEXTS: "Textes"
//             }
//         },
//         FILTERS: {
//             OLD_PROJECTS: "Anciens projets",
//             PROJECTS_ARCHIVED: "Projets archivés",
//             IN_APPROVAL_STATUS: "En attente de validation",
//             DISPLAY_CLOSED: "Afficher les requêtes déjà traitées",
//             MY_PROJECTS: "Mes projets",
//             PROJECTS: "Projets en cours",
//             DATE: "Date",
//             DATES: "Dates",
//             DATE_RANGE: {
//                 START_DATE: "Début",
//                 END_DATE: "Fin"
//             },
//             DATE_RANGE_ERRORS: {
//                 START_DATE: "Début",
//                 END_DATE: "Fin"
//             },
//             RESET: "Réinitialiser",
//             SEARCH: "Lancer la recherche",
//             REQUEST_TYPE_LABEL: "Type de requête",
//             REQUEST_TYPE: {
//                 NONE: "Aucun",
//                 VISUALS_ISOLATION: "Isolation de visuel",
//                 PDF_REPOSITORY: "Dépôts de PDF"
//             },
//             REDELIVERY_TYPE_LABEL: "Type de relivraison",
//             REDELIVERY_TYPE: {
//                 EXE: "EXE",
//                 REPRO: "REPRO"
//             }
//         },
//         HD: {
//             ARCHIVE: "Reprise d’archive",
//             COMMCUSTOMER: "Description HD",
//             ELEMENTS: "Éléments pour la HD",
//             HD: "HD",
//             HDSHOOT: "Shutter",
//             OPERATOR: "Infographiste HD",
//             PLANNING: {
//                 EPSON: "Epson 1 Client / TC",
//                 LIVRAISON: "Livraison",
//                 PLANNING: "Planning",
//                 R0: "R0 Master",
//                 R1: "R1 Master"
//             },
//             SHOOTCUSTOMER: "Shoot Client",
//             SHOOTER: "Shoot HD"
//         },
//         INFO: {
//             AGENCE: "Agence",
//             APPROVER1: "Approbateur 1",
//             APPROVER2: "Approbateur 2",
//             APPROVER3: "Approbateur 3",
//             APPROVER4: "Approbateur 4",
//             APPROVERS: "Approbateurs",
//             MEMBERS: "Membres",
//             ARCHIVE: "Archive",
//             BASE: "Base de travail",
//             CHILD: "Numéro Enfant",
//             CODEARTICLE: "Code Article",
//             CONTACT: "Contact",
//             CONTRAINTE: "Contraintes Techniques (imprimeur)",
//             CPP: "CPP",
//             CREA: "Créa",
//             CREA_NAME: "Nom + N°Créa",
//             CREA_TYPE: "Type de créa",
//             CUSTOMER: "Client",
//             CUSTOMER_INVOICE: "Client facturation (optionnel)",
//             CUSTOMER_CODE: "Code client",
//             DATEVALID: "Date validation",
//             DESIGNATION: "Désignation",
//             DESIGNATIONGAMME: "Désignation",
//             DESIGNATION_PRODUIT: "Désignation produit",
//             FAMILY: "Famille",
//             FILETMINN: "Filet mini Négatif",
//             FILETMINP: "Filet mini Positif",
//             IDENTIFIANT: "Identifiant",
//             IDENTIFIER: "Numéro du Job Enfant",
//             IDMAITRE: "Numéro Job Maître",
//             INFO: "Informations Générales",
//             LANGUAGES: "Langues",
//             MASTER: "Numéro Master",
//             NUMCREA: "N° Créa validée",
//             NUMDIADEM: "Numéro Diadem",
//             NUMTEAM: "Numéro Team Créatif",
//             OPERATOR: "Infographiste référent",
//             PLAN: "Plan",
//             PRINT: "Impression",
//             PRINTER: "Imprimeur",
//             PROCESS: "Process",
//             RANGE: "Marque",
//             REFNUMBER: "Nombre de référence",
//             RELECTURE_JOB: "Numéro de relecture",
//             RELECTURE_JOB_EXE: "N° de relecture EXE",
//             RELECTURE_JOB_REPRO: "N° de version REPRO",
//             SERVICE: "Services",
//             STATUS: "Statut",
//             SUPPORT: "Support",
//             TEXTMININ: "Textes mini Négatif",
//             TEXTMINIP: "Textes mini Positif",
//             TYPEJOB: "Type",
//             ZONEDEBORDDEV: "Zone débord Developpement",
//             ZONEDEBORDLAIZE: "Zone débord Laize",
//             ZONESECUCOUP: "Zone sécurité coupe",
//             ZONESECURAIN: "Zone sécurité raineur",
//             SUPPORT_COMM: "Spécificités imprimeur",
//             RIVERFLOW: "Riverflow",
//             RIVERFLOW_JOBTYPES: "Type de job a créer",
//             RIVERFLOW_DATE: "Date du Job",
//             CREATE_IN_RIVERFLOW: "Créer le Job dans Riverflow"
//         },
//         LAST_REVISION: "Dernier Ctrl client (exe) le",
//         LAST_VERSION: "Dernier Ctrl client (repro) le",
//         MASTER: "Projet parent",
//         OTHER: "Autre",
//         PREFILL: "Pré-remplir les champs",
//         EASYDIADEM: "Easydiadem",
//         REPRO: {
//             CHROMIE: {
//                 CHROMIE: "Chromie",
//                 CIBLE: "Cible couleur fournie",
//                 ENCR: "Encrage maximum",
//                 PTMAX: "Point maxi",
//                 PTMIN: "Point mini",
//                 REPROPROFIL: "Profil",
//                 TRAM: "Trame"
//             },
//             FILE1: "Fichiers à fournir",
//             FILE2: "PSD",
//             OPERATOR: "Infographiste REPRO",
//             REPRO: "REPRO",
//             RETRAIT: {
//                 BLANC: "Retrait blanc",
//                 COULEUR: "Retrait couleur",
//                 VERNIS: "Retrait Vernis"
//             },
//             TRAP: "Trapping",
//             TRAP_RETRAIT: "Trapping et Retrait"
//         },
//         SAVE: "Sauvegarder projet",
//         SEARCH: "Rechercher un projet...",
//         TYPE_JOB_REQUIRED: "Type de job requis"
//     },
//     PROJECTS: "Projets",
//     PROJECTTABLE: {
//         PAGE_TITLE: "Table des projets"
//     },
//     FACE: "De Face",
//     "THREE-QUARTER-LEFT": " De 3/4 à gauche",
//     "THREE-QUARTER-RIGHT": " De 3/4 à droite",
//     "FACE-INCO": "Face légale INCO",
//     RESTART: "Redémarrer",
//     REQUIRED: "Champ requis",
//     PR_REQUIRED: "EXE : Penser à créer une fiche texte (pour éviter le plantage de flux)",
//     RESET_DIALOG: {
//         TEXT: "Les textes et les champs personnalisés seront supprimés dans toutes les langues",
//         TITLE: "Voulez-vous réinitialiser la fiche textes ?"
//     },
//     SAVE: "Sauvegarder",
//     SELECT_JOB_CHILD_REFERENCE: "Sélectionner un projet enfant",
//     SELECT_JOB_MASTER_REFERENCE: "Sélectionner un projet parent",
//     SELECT_FILTER_FIELDS: "Cliquez ici pour sélectionner les champs de la fiche textes",
//     SELECT_REFERENCE: "Sélectionner une référence",
//     SHOOTING: "Quelle(s) prise(s) de vue pour votre Packshot (il sera livré aux formats jpeg, png - Basse déf et Haute déf) : ",
//     SELECT_SHOOTING: "Merci de sélectionner les prises de vue",
//     SOMETHING_WENT_WRONG: "Une erreur est survenue",
//     SPLITVIEW: {
//         PAGE_TITLE: "VUE COMPAREÉ"
//     },
//     SUBMIT: "Envoyer",
//     SUCCESS: {
//         CREATE: "Vos textes ont été créés",
//         FORM: {
//             CREATE: "Votre fiche textes a été créée",
//             SAVE: "Votre fiche textes a été enregistré",
//             SUBMIT: "Fiche textes envoyée !"
//         },
//         SAVE: "Vos textes ont été enregistrés",
//         SUBMIT: "Textes envoyés !"
//     },
//     SYSTEM: "Système",
//     TABLE: "Tableau des projets",
//     COMPARE: "Comparer",
//     TEL: "Téléphone",
//     TEXT: {
//         COPIED: "Texte copié !",
//         PASTED: "Texte collé depuis le presse papier"
//     },
//     TEXTOK: {
//         SUBMIT: "Textes envoyés !",
//         TITLE: "Textes ok"
//     },
//     TEXT_APPRO_IN_PROGRESS: "Traitement approbateurs",
//     TEXT_IN_PROGRESS: "En cours de traitement",
//     TEXT_SEND: "Textes envoyés",
//     TITLE: "TITRE",
//     ARE_YOU_SURE: "Êtes-vous sûr ?",
//     ARE_YOU_SURE_ARCHIVE: "Le projet sera archivé",
//     ARE_YOU_SURE_UNARCHIVE: "Le projet sera désarchivé",
//     ARE_YOU_SURE_DELETE_GROUP_TEXT: "Le groupe sera supprimé. (Les groupes supprimés seront retirés des projets en cours)",
//     ARE_YOU_SURE_DELETE_LANGUAGES: "Les textes de cette langue seront supprimés",
//     DELETE: "Supprimer",
//     TOOLBAR: {
//         IN_PRODUCTION: 'Envoyer en production',
//         CREATE: "Créer fiche textes",
//         FIELD: "Ajouter un champ personnalisé",
//         CUSTOM_COLUMN: 'Ajouter une colonne personnalisé',
//         LANGUAGE: "Ajouter une langue",
//         FORM: "Réinitialiser la fiche textes",
//         PREFILL: "Pré-remplir les champs",
//         SUBMIT: "Envoyer la fiche textes",
//         SUBMIT_MODIF: "Envoyer les modifications"
//     },
//     TRAME: "Linéature de trame",
//     TYPE_TRAME: "Type de Trame",
//     TXT_SUBMIT: "Textes envoyés",
//     TYPE: "File Epson",
//     UNSAVED_CHANGES: "Des modifications n'ont pas été sauvegardées. Quitter quand même ?",
//     USERS: "Utilisateurs",
//     GROUPS: "Groupes",
//     USER: {
//         ARE_YOU_SURE_DELETE_USER_TEXT: "L'utilisateur sera supprimé",
//         BOTH_USED: "Nom d'utilisateur et e-mail déjà utilisés",
//         CREATE: "Créer l'utilisateur",
//         CREATE_OTHER: "Souhaitez-vous créer un autre utilisateur ?",
//         CREATE_USER: "Créer un utilisateur",
//         ADD_USER: "Ajouter un utilisateur",
//         CUSTOMER: "Client",
//         DELETE: "Supprimer",
//         DELETE_SUCCESS: "Utilisateur supprimé",
//         EDIT_ACCOUNT_SUCCESS: "Compte mis à jour",
//         EDIT_SUCCESS: "Utilisateur mis à jour",
//         ADD_SUCCESS: "Utilisateur créé",
//         EDIT_FAILED: "Impossible de mettre à jour l'utilisateur",
//         EDIT_USER: "Éditer l'utilisateur",
//         EMAIL: "E-mail",
//         FIRSTNAME: "Prénom",
//         MANAGE_USERS: "Gérer les utilisateurs",
//         GROUP: "Groupe",
//         LASTNAME: "Nom",
//         MAIL_USED: "E-mail déjà utilisé",
//         MY_ACCOUNT: "Mon compte",
//         PASSWORD: {
//             CURRENT: "Mot de passe actuel",
//             NEW: "Nouveau mot de passe",
//             NEW_CONFIRM: "Confirmer le nouveau mot de passe",
//             NOT_SAME: "Les deux mots de passe sont différents",
//             RESET: "Modifier mon mot de passe",
//             UPDATE_SUCCESS: "Mot de passe mis à jour",
//             WRONG_CURRENT: "Mot de passe actuel incorrect"
//         },
//         UPDATE: "Mettre à jour",
//         USER: "Utilisateur",
//         USERNAME: "Login",
//         USERNAME_USED: "Nom d'utilisateur déjà utilisé",
//         IS_DIADEM: "L'utilisateur fait partie de Diadem",
//         IS_DIADEM_SHORT: "Utilisateurs Diadem",
//     },
//     USERNAME: "Nom d'utilisateur",
//     SELECT_DOC: "Merci de sélectionner un document",
//     GROUP: {
//         DELETE_SUCCESS: "Groupe supprimé",
//         CREATE: "Créer le groupe",
//         CREATE_GROUP: "Ajouter un groupe",
//         CUSTOMER: "Client",
//         CUSTOMERS: "Clients",
//         CPP: "CPP",
//         MANAGE_GROUPS: "Gérer les groupes",
//         BRANDS: "Marques",
//         DELETE: "Supprimer",
//         EDIT_GROUP: "Éditer le groupe",
//         EDIT_SUCCESS: "Groupe mis à jour",
//         GROUP: "Groupe",
//         UPDATE: "Mettre à jour",
//         NAME: "Nom",
//         USERS: "Utilisateurs",
//         APPROBATION_BEHAVIOR: "Comportement d'approbation",
//         ONE_FOR_ALL: "Un vaut pour tous",
//         INDIVIDUAL: "Individuel",
//         ONE_FOR_ALL_DESCR: "N'importe quel membre du groupe peut choisir de valider/rejeter l'approbation, et suite à son choix le statut de tout le groupe est affecté en conséquence.",
//         INDIVIDUAL_DESCR: "Chaque membre du groupe doit valider/rejeter l'approbation avant que le statut du groupe soit calculé en conséquence."
//     },
//     INVALID_FILE: "Le fichier est invalide",
//     INVALID_FILE_SIZE: "Le fichier est trop volumineux",
//     INVALID_FILE_TYPE: "Le type du fichier est incorrecte",
//     VALID: "Valider",
//     VALIDATORS: {
//         "FORMDIALOG-CPP": "Veuillez sélectionner un CPP valide",
//         "FORMDIALOG-PRINTERS": "Veuillez sélectionner un imprimeur valide",
//         "FORMDIALOG-CUSTOMER": "Veuillez sélectionner un client valide",
//         "FORMDIALOG-IDENTIFIANT": "Veuillez sélectionner un identifiant valide",
//         "FORMDIALOG-FAMILY": "Veuillez sélectionner une famille valide",
//         "FORMDIALOG-FORM": "Veuillez saisir un nom de champ",
//         "FORMDIALOG-LANGUAGE": "Veuillez sélectionner au moins une langue",
//         "FORMDIALOG-OPERATOR": "Veuillez sélectionner un infographiste valide",
//         "FORMDIALOG-PROJECT": "Veuillez sélectionner un projet valide",
//         "FORMDIALOG-RANGE": "Veuillez sélectionner une marque valide",
//         "FORMDIALOG-CONTACT": "Veuillez sélectionner un contact valide",
//         "FORMDIALOG-CUSTOMER_INVOICE": "Veuillez sélectionner un client de facturation valide"
//     },
//     VALID_EMAIL: "Email non valide",
//     INVALID_USERNAME: "Username invalide",
//     INVALID_PASSWORD: "Mot de passe invalide",
//     VALID_TEL: "Téléphone non valide",
//     VIEW: {
//         BUTTON: "Voir",
//         PROJECT_REF: "Référence",
//         ID_MAITRE: 'ID Maître',
//         COMPARE: "Comparer",
//         DOCUMENT_APPROVER_REJECT: "L'approbateur a rejeté le document.",
//         DOCUMENT_YOU_ACCEPT: "Vous avez accepté le document.",
//         DOCUMENT_YOU_REJECT: "Vous avez rejeté le document.",
//         DOCUMENT_APPROVER_ACCEPT: "L'approbateur a apprové le document.",
//         INTERN_CONTROL_REJECT: "Le contrôle interne a été rejeté.",
//         INTERN_CONTROL_APPROVED: "Le contrôle interne a été approuvé.",
//         DOCUMENT_IN_PRODUCTION: "Le document est en cours de production.",
//         PENDING_DELIVERY: "En attente de livraison",
//     },
//     WARNING: "Attention",
//     REPRO_IN_PROGRESS: "REPRO en cours",
//     EXE_IN_PROGRESS: "Exe en cours",
//     YES: "Oui",
//     NO: "Non",
//     ENTER_VALUE: "Entrez une valeur",
//     EDIT: "Editer",
//     MUST_BE_IN_LIST: "L'element doit etre issue de la liste",
//     SYNCBDD: "Synchroniser la base client Logipub",
//     TXT_PR: "Fiche Textes",
//     VALIDATE: "Valider",
//     NEXT: "Suivant",
//     PREVIOUS: "Précédent",
//     REMOVE_LANGUE: "Supprimer la langue",
//     SEARCH: "Rechercher",
//     SELECT_LANGUAGE: "Sélectionner une ou plusieurs langues",
//     SERVICE: {
//         PROJECT: 'Projets',
//         VALIDATORS: {
//             PROJECT: "Veuillez sélectionner un projet valide",
//             PRINTERS: "Veuillez sélectionner un imprimeur valide"
//         },
//         PRINTERS: 'Imprimeurs'
//     },
//     NOTIFICATIONS: {
//         LIST: 'Notifications',
//         CARTOUCHE_GENERATED: "Le cartouche {{ type }} du projet {{ projectIdentifier }} a été généré !",
//         PROOFSCOPE_GENERATED: "{{ identifier }} - La relecture interne {{ flow }} à été mis-à-jour !",
//         FILE_VALIDED: "{{ identifier }} - La relecture interne {{ flow }} est terminée !",
//         SENT_TO_CLIENT: "{{ identifier }} - La relecture interne a été publiée au client",
//         CHECK_CPP: "{{ identifier }} - Un job dont vous êtes CPP est prêt à être contrôlé.",
//         BRIEF_FILES_ARE_READY: "{{ identifier }} - Les fichiers du brief client ont été copiés",
//         FILES_ARE_READY: "{{ identifier }} - Les fichiers ont été copiés",
//         START_DELIVERY: "{{ identifier }} - La livraison est prête à être contrôlée",
//         CONTROL_DELIVERY_VALIDATED: "{{ identifier }} - Contrôle de livraison terminé, livraison validée",
//         CONTROL_DELIVERY_FAILED: "{{ identifier }} - Contrôle de livraison terminé, livraison refusée",
//         FINAL_DELIVERY: "{{ identifier }} - La livraison a été finalisée",
//         ERROR: {
//             UPDATE_DOC: "{{ identifier }} - Le flux de production a planté en {{ flow }}",
//             VALID_FILE: "{{ identifier }} - Le flux de validation du fichier a planté en {{ flow }}",
//             BRIEF_FILES_ARE_READY: "{{ identifier }} - Les fichiers n'ont pas pu être copiés",
//             FILES_ARE_READY: "{{ identifier }} - Les fichiers n'ont pas pu être copiés",
//             START_DELIVERY: "{{ identifier }} - La livraison a planté en {{ flow }}",
//             CONTROL_DELIVERY_FAILED: "{{ identifier }} - Le refus du contrôle de livraison a planté en {{ flow }}",
//             CONTROL_DELIVERY_VALIDATED: "{{ identifier }} - La validation du contrôle de livraison a planté en {{ flow }}",
//             FINAL_DELIVERY: "{{ identifier }} - La finalisation de la livraison a planté en {{ flow }}",
//             POST_VALIDATION: "{{ identifier }} - La post-validation a planté en {{ flow }}",
//         }
//     },
//     JOB_NO_PROOFSCOPE: "Ce job n'a pas de fiche textes associée",
//     RESET_PROD: {
//         BTN: "Réinitialiser la production",
//         TITLE: "Réinitialisation de la production",
//         TEXT: "Ceci va réinitialiser la relecture à l'etat 'Prêt'."
//     },
//     UPLOADING: 'Chargement',
//     UPLOADING_IN_PROGRESS: "Les fichiers sont en train d'être chargés.",
//     DOWNLOADING: 'Téléchargement',
//     DOWNLOADING_IN_PROGRESS: "Les fichiers sont en train d'être téléchargés.",
//     ACTUAL_PROJECTS: "Projets en cours",
//     ADD_CHILD_PROJECT: "Ajouter projet enfant",
//     THUMB: "Vignette",
//     JOB_NAME: "Nom du job",
//     EXTERN_STATE: "Statut général",
//     INTERN_STATE: "Statut interne",
//     CREATION_DATE: "Créé par",
//     ACTIONS: "Actions",
//     ED_JOB: "Projet EasyDiadem",
//     DASHBOARDHISTORY: {
//         VALIDATIONS_HISTORY: "Historique des validations",
//         LOOP: {
//             INTERN: "Boucle interne",
//             EXTERN: "Boucle externe",
//         },
//         PLEASE_SELECT_A_VERSION: "Veuillez sélectionner une version",
//         USER: "Utilisateur",
//         DATE_HOUR: "Date / heure",
//         STATE: "Etat",
//         NO_HISTORY: "Aucun historique disponible",
//         STEPS: "Etapes",
//         MEMBERS: "Membres",
//         CATEGORY: "Catégorie",
//         CONTROL: "Contrôle",
//     },
//     VERSION_SHORTNAME: "ver.",
//     NO_VERSION: "Pas de version",
//     SPLITVIEWS: "Relectures",
//     INTERN_TITLE: "Interne",
//     INTERN_TITLE_SHORT: "Int.",
//     EXTERN_TITLE: "Externe",
//     EXTERN_TITLE_SHORT: "Ext.",
//     COMPARE_SHORT: "Comp.",
//     RELECTURES: "Relectures",
//     SELECT_DOCUMENT_TO_COMPARE: "Sélectionner les versions à comparer",
//     DOCUMENT_A: "Document A",
//     DOCUMENT_B: "Document B",
//     DETAILS: "Détails",
//     SEE_PROJECT_HISTORY: "Voir l'historique du projet",
//     FILTERS: {
//         NAME_OR_ID: "Nom ou identifiant",
//         START_DATE: "Date début",
//         END_DATE: "Date fin",
//         REINIT: "Réinitialiser",
//         SEARCH: "Recherche"
//     },
//     RELECTURE: "Relecture",
//     YOU_DONT_HAVE_THE_PRIVILEGE: "Vous n'avez pas le privilège",
//     EZD_TABLE: {
//         TITLE: {
//             DATE: "Date",
//             CLIENT: "Client",
//             REF_REQUEST: "Réf. demande",
//             REF_PROJECT: "Réf. projet",
//             NAME: "Nom de la demande",
//             TYPE: "Type de demande",
//             INFO: "Info",
//             ACTIONS: "Actions"
//         }
//     },
//     RESET_PASSWORD: "Réinitialiser le mot de passe",
//     ARE_YOU_SURE_RESET_PASSWORD_TEXT: "Le mot de passe de l'utilisateur {{ username }} sera réinitialisé.",
//     ARE_YOU_SURE_RESET_PASSWORD_TEXT_2: "Il recevra son nouveau mot de passe par email.",
//     ATTRIBUTES_NAME: {
//         TITLE: "Attributs de l'utilisateur",
//         CPP: "CPP",
//         EXE: "Exe",
//         REPRO: "Repro",
//         FAB: "Fab",
//         OPERATOR: "Opérateur",
//         CLIENT: "Client",
//     },
//     FORGOT_PASSWORD: "Mot de passe oublié?",
//     USERNAME_OR_EMAIL: "Nom d'utilisateur ou email",
//     REQUIRED_USERNAME_OR_EMAIL: "Nom d'utilisateur ou email requis",
//     ASK_FOR_REINIT: "Demander la réinitialisation",
//     ASK_FOR_REINIT_TEXT: "Votre demande a bien été prise en compte. Si l'adresse email associée au compte est valide, vous recevrez un email avec un lien pour réinitialiser votre mot de passe.",
//     SET_NEW_PASSWORD: "Définir un nouveau mot de passe",
//     BACK_TO_LOGIN_PAGE: "Retour à la page de connexion",
//     RESET_PASSWORD_DIALOG: {
//         ERROR: {
//             TITLE: "Une erreur est survenue",
//             TEXT: "Merci de vérifier les informations fournies, ou réessayez plus tard."
//         },
//         REQUEST: {
//             SUCCESS: {
//                 TITLE: "Demande de réinitialisation envoyée",
//                 TEXT: "Si le nom d'utilisateur ou l'email fourni correspond à un compte existant, vous recevrez un email avec un lien pour réinitialiser votre mot de passe."
//             }
//         },
//         SET: {
//             SUCCESS: {
//                 TITLE: "Mot de passe mis à jour",
//                 TEXT: "Vous pouvez désormais vous connecter avec votre nom d'utilisateur et votre nouveau mot de passe."
//             }
//         }
//     }
// };