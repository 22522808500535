import { RequestType } from "src/app/LIVECO.define";
import { BaseModel } from "../../base.model";
import { FlowType } from "../../proofreading.model";
import { IPdfRepository } from "../project.model";

export interface IGeneralChild {
    id_jobteam: string;
    id_codearticle: string;
    designation: string;
    type_job: string;
    graphic_type: string;
    service_exe: string | boolean;
    service_hd: string | boolean;
    service_repo: string | boolean;
    service_repro: string | boolean;
    service_other: string | boolean;
    category?: string;
    sub_category?: string;
    is_old?: boolean;
    language: string;
}

export interface IEDEmbededRequest {
    _id: string,
    ref: string,
    type: RequestType,
    flow: FlowType | null,
    pdf_repository?: IPdfRepository;
    // briefing?: IBriefingRequest;
}

export interface IRiverflowEmbededData {
    createJob: boolean,
    jobDate: Date,
    jobTypes: string[];
}

export interface IPrinterConstraints {
    filetMinPos: string;
    filetMinNeg: string;
    textMinPos: string;
    textMinNeg: string;
    secuCoupe: string;
    secuRain: string;
    colorDebDev: string;
    colorDebLaize: string;
    impression_comm_support: string;
}

export interface IReproSpec{
    trapping: string;
    blanc:  string;
    retrait: string;
    vernis: string;
    trame: string;
    profil: string;
    tauxencragemax: string;
    pointmini: string;
    pointmaxi: string;
}

export class GeneralChild extends BaseModel<IGeneralChild> implements IGeneralChild {
    id_jobteam: string;
    id_codearticle: string;
    designation: string;
    type_job: string;
    graphic_type:string;
    service_exe: string | boolean;
    service_hd: string | boolean;
    service_repro: string | boolean;
    service_repo: string | boolean;
    service_other: string | boolean;
    category?: string | undefined;
    sub_category?: string | undefined;
    is_old?: boolean;
    language: string;

    constructor(data: Partial<IGeneralChild> = {}) {
        super(data);
        this.init(data);
    }
}